import Layout from "../../../components/layout/Layout";
import Img1 from "../../../assets/img/gallery-single/big-box-img-1.jpg";
import Img2 from "../../../assets/img/gallery-single/user-1.png";
import Img3 from "../../../assets/img/gallery-single/box-img-1.jpg";
import Img4 from "../../../assets/img/gallery-single/box-video-img.jpg";
import { consultancyData } from "../../../assets/data/dummyData";
import b3 from "../../../assets/image/b3.jpeg";
import b4 from "../../../assets/image/b4.jpeg";
import Cf1 from "../../../assets/image/Consultancy/Cf1.jpg";
import cf12 from "../../../assets/image/Consultancy/cf12.jpg";
import cf11 from "../../../assets/image/Consultancy/cf11.jpg";
import cf10 from "../../../assets/image/Consultancy/cf10.jpg";
import cf6 from "../../../assets/image/Consultancy/cf6.jpg";
import cf7 from "../../../assets/image/Consultancy/cf7.jpg";
import cf8 from "../../../assets/image/Consultancy/cf8.jpg";
import cf9 from "../../../assets/image/Consultancy/cf9.jpg";
import Carousel from 'better-react-carousel'
interface consultancyType {
  key: number;
  content: string;
}

const Consultancy = () => {

  return (
    <Layout>
      <section className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]">
        <h2 className="flex items-center justify-center mx-auto pt-18 lg:pt-0">
          Consultancy
        </h2>
      </section>
      <section className="gallery-single box-spach py-16">
        <div className="container">
          <h3 className="section-title-white-center mt-0">APIARY MANAGEMENT AND BEEKEEPING CONSULTING ACTIVITIES</h3>
          {/* <div className="w-full bg-white border border-black-200 rounded-lg shadow dark:bg-black-800 dark:border-black-700">  
                
                <TopSiliConsul/>
              </div> */}
          <Carousel cols={2} rows={1} gap={10} loop>
            <Carousel.Item>
              <img width="100%" src={cf6} />
              <div className="show p-4 bg-white rounded-lg md:p-4 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Hive Inspection and Assessment </h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>We visit your beekeeping operations to inspect hives, assess the health of the bee colonies and identify any issues or challenges. This can include checking for signs of disease, pests, or other problems that may be affecting your colony.</p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src={cf6} />
              <div className="show p-4 bg-white rounded-lg md:p-4 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Hive Management and Recommendations </h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>We often provide recommendations on how to manage bee colonies effectively. This can include advice on hive placement, hive design and hive maintenance.  </p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src={cf7} />
              <div className="show p-4 bg-white rounded-lg md:p-1 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Disease and Pest Management </h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>Your apiaries can often face challenges related to diseases and pests that can harm bee colonies. We provide advice on strategies for prevention and treatment. </p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src={cf8} />
              <div className="show p-4 bg-white rounded-lg md:p-4 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Honey Production and Harvesting </h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>Beekeepers may seek guidance on maximizing honey production while ensuring the health and well-being of their bees. Consultants can provide recommendations on harvesting methods, timing, and equipment.</p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src={cf9} />
              <div className="show p-4 bg-white rounded-lg md:p-4 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Queen Rearing and Bee Breeding </h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>Through our expertise in bee colony multiplication, we use Ginter kits machine and NUC Boxes to supply the bee colony anywhere in Rwanda.  </p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src={cf10} />
              <div className="show p-4 bg-white rounded-lg md:p-4 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Environmental Considerations</h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>We advise on forage availability (plants and flowers for bees) To improve bee health, honey production and provide guidance on creating bee-friendly environments.</p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <img width="100%" src={cf12} />
              <div className="show p-4 bg-white rounded-lg md:p-4 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Training and Education</h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>We offer practical training sessions and workshops for beekeepers and beginners to improve their knowledge and skills in good beekeeping practices and bee health management.</p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <img width="100%" src={cf11} />
              <div className="show p-4 bg-white rounded-lg md:p-4 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white section-title-white-center">Swam Catching</h2>

                <ul role="list" className="space-y-2 text-gray-500 dark:text-gray-400">
                  <li className="flex space-x-1 items-center">
                    <p>We provide services of removing bees in homes, offices and other locations when not needed or attacked. You contact us for assistant, within 1 to 2 hours, depending on the location the service is completed.</p>
                  </li>
                </ul>
              </div>
            </Carousel.Item>

            {/* ... */}
          </Carousel>

          <div
            className="user bg-cover-center bg-[url('assets/image/b3.jpeg')]"
            data-background=""
          >
            <div className="user-profile">
              <div className="icon">
                <svg viewBox="0 0 736 657" xmlns="http://www.w3.org/2000/svg">
                  <path d="M64.2675 1.66667C47.4675 4.2 29.0675 17.2667 19.6009 33.5333C10.5342 49 10.6675 45.9333 10.6675 171.133C10.6675 293.933 10.6675 293.133 18.4009 307.533C22.9342 316.067 36.1342 329.267 44.9342 333.933C57.2009 340.6 65.0675 341.667 105.868 341.667H142.934L142.134 348.867C140.668 360.6 135.201 380.733 130.134 393.133C120.934 415.533 109.468 431.8 92.1342 446.467C78.6675 458.067 69.4675 463.933 48.6675 474.6C26.8009 485.667 18.4009 491.8 12.0009 501.933C3.86753 514.333 0.667529 525.267 0.800862 540.333C0.800862 551.4 1.3342 554.2 4.9342 563C12.6675 581.4 31.8675 620.867 37.2009 629.133C44.0009 639.4 54.2675 647.4 67.6009 652.6C76.4009 656.067 79.8675 656.733 90.0009 656.733C103.734 656.867 109.601 655.267 130.268 645.667C172.668 625.8 210.668 599.8 243.068 568.333C272.934 539.533 292.934 512.6 309.868 478.333C329.201 439.4 338.401 405.8 345.201 351C350.934 303.8 351.334 292.467 351.334 171C351.334 39.9333 351.868 47 341.334 31.4C334.534 21.2667 330.534 17.1333 320.934 10.8667C305.201 0.599998 312.801 1.13333 185.334 0.866665C122.668 0.733332 68.1342 1.13333 64.2675 1.66667ZM289.068 163C289.468 263.133 288.534 299.533 284.534 334.333C280.401 371 274.001 401.933 266.001 424.333C242.268 490.333 187.201 547.8 112.001 585C90.4009 595.8 90.0009 595.933 87.4675 592.067C82.5342 584.333 62.5342 539.933 63.2009 538.2C63.6009 537.133 67.7342 534.467 72.2675 532.333C83.6009 527.267 101.734 516.733 115.734 507C151.201 482.733 178.268 446.467 192.668 404.067C202.268 375.533 206.268 349.533 207.601 307.667L208.401 279.533L142.134 279.667C105.601 279.667 75.0675 279.267 74.2675 278.733C73.2009 278.067 72.8009 247.933 72.6675 172.067C72.5342 113.933 72.6675 65.5333 73.0675 64.6C73.4675 63.2667 95.8675 63 181.201 63.2667L288.668 63.6667L289.068 163Z" />
                  <path d="M448.001 1.79997C438.134 3.39997 423.334 10.8666 416.134 17.9333C408.667 25.2666 400.801 37.2666 397.867 46.0666C395.601 52.4666 395.467 62.4666 395.067 165.667C394.667 244.867 394.934 281 396.001 287.267C400.267 312.6 419.334 333 444.934 339.667C450.801 341.267 461.201 341.667 490.134 341.667H527.601L526.534 348.6C523.067 370.333 516.401 391 507.201 408.333C492.134 436.467 470.401 455.933 432.001 475C412.534 484.733 405.334 489.933 398.267 499.267C386.001 515.667 381.601 536.067 386.401 553.667C388.934 562.867 413.467 616.733 419.467 626.467C431.467 645.933 452.001 657 475.334 657C487.601 657 493.867 655.133 515.201 645.267C590.001 610.867 650.667 556.867 687.201 492.333C707.601 456.467 720.934 413.8 728.001 362.333C734.534 314.867 734.934 304.733 735.601 179C736.134 70.7333 736.001 58.8666 734.001 50.6C727.867 25.4 705.867 5.26663 680.667 1.66663C668.934 0.0666324 458.267 0.199966 448.001 1.79997ZM673.734 78.6C674.934 103.133 673.601 270.333 672.001 296.333C666.667 383.267 652.934 433.4 621.467 480.2C610.001 497.133 601.734 507 585.201 523.267C559.467 548.467 534.401 566.067 497.867 584.6C481.201 593 475.467 595.267 473.867 594.333C472.001 593.133 446.667 541.533 446.667 538.867C446.667 538.2 451.601 535.267 457.734 532.2C489.201 516.333 512.267 500.067 531.201 480.2C572.801 436.467 591.867 379.667 592.001 299.4C592.001 282.6 591.734 280.467 589.734 279.8C588.401 279.533 567.201 279.4 542.667 279.667C518.134 279.933 488.801 279.8 477.734 279.4L457.334 278.6V171.667C457.334 112.867 457.734 64.3333 458.267 63.9333C458.667 63.4 507.201 63 566.001 63H672.934L673.734 78.6Z" />
                </svg>
              </div>
              <img src={b4} alt="user" />
            </div>
            <div className="user-content">
              <h4>
                " Give a healthy start to your day with the spoonful of BeeGulf Honey with a glass of warm water."
              </h4>
              <p>

                Stay Fit, Feel Young!
              </p>
            </div>
          </div>
          <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-4">

            <div className="show p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab section-title-white-center">
              <h2 className="section-title-white-center mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white items-center bg-gold">Tips for Beekeeping  A</h2>

              <ul role="list" className="space-y-4 text-gray-500 dark:text-gray-400">
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Educate Yourself: Before you start beekeeping, take the time to learn about bees, their behavior, and the basics of beekeeping. There are many books, online courses, and local beekeeping clubs that can help you get started.</span>
                </li>
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Invest in Proper Equipment: Make sure you have the right protective gear, beehives, and tools. This includes a bee suit, gloves, a smoker, hive tools, and more.</span>
                </li>
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Choose the Right Location: Select a suitable location for your beehives. Consider factors like sun exposure, proximity to water sources, and accessibility.</span>
                </li>
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Start with a Mentor: If possible, find an experienced beekeeper who can mentor you. Learning from someone with practical experience can be invaluable.</span>
                </li>
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Regular Hive Inspections: Regularly inspect your hives to ensure the health of your bees. Look for signs of disease, pests, and the overall well-being of the colony.</span>
                </li>
              </ul>
            </div>


          </div>
          <div className="box-gallery">
            <div className="box-img">
              <img src={Img3} alt="gallery" />
            </div>
            <div className="box-img">
              <img src={Img3} alt="gallery" />
            </div>
          </div>
          <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-5">

            <div className="show p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab section-title-white-center">
              <h2 className="section-title-white-center mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white items-center bg-gold">Inspirations for Beekeepers</h2>

              <ul role="list" className="space-y-4 text-gray-500 dark:text-gray-400 section-title-white-center">
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">The Art of Observation: Beekeeping is not just about honey production; it's a journey into the fascinating world of these tiny creatures. Observing the complex social structure and behavior of bees can be profoundly inspiring.</span>
                </li>
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Environmental Stewardship: Beekeeping can be a way to actively contribute to the environment. Bees play a critical role in pollination, helping plants grow and produce food for humans and wildlife. By keeping bees, you're participating in an important ecological process.</span>
                </li>
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Honey and Other Hive Products: The joy of harvesting your own honey, beeswax, and other hive products can be immensely rewarding. You can make various products from these materials, from candles to skincare products.</span>
                </li>
                <li className="flex space-x-2 items-center">
                  <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="leading-tight">Self-Sufficiency: Beekeeping can contribute to self-sufficiency and sustainable living. You can reduce your reliance on store-bought honey and other bee-related products.</span>
                </li>
              </ul>
            </div>


          </div>
          <div className="box-video box-img">
            <button
              className="play-video-btn v1 venobox relative"
              data-vbtype="video"
              data-maxwidth="800px"
              data-autoplay="true"
              data-href="https://youtu.be/sYNgtwsfhx4?start=65"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                className="absolute top-6 left-6"
              >
                <path d="M26.17 12.37 9 2.45A3.23 3.23 0 0 0 7.38 2 3.38 3.38 0 0 0 4 5.38v21.29a3.33 3.33 0 0 0 5.1 2.82l17.19-10.86a3.65 3.65 0 0 0-.12-6.26z"></path>
              </svg>
            </button>
            <img src={b3} alt="gallery" />
          </div>
          <div className="tags-social">
            <div className="socials-links-box">

            </div>
          </div>
          <div className="prev-next">
            <div className="prev-link">
              <a href="#x" className="flex items-center">
                <span className="icon">
                  <svg viewBox="0 0 50 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM50 7L1 7V9L50 9V7Z"></path>
                  </svg>
                </span>
                <span className="text">previous</span>
              </a>
            </div>
            <div className="next-link">
              <a href="#x" className="flex items-center">
                <span className="text">next</span>
                <span className="icon">
                  <svg viewBox="0 0 50 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M49.7071 7.29289C50.0976 7.68342 50.0976 8.31658 49.7071 8.70711L43.3431 15.0711C42.9526 15.4616 42.3195 15.4616 41.9289 15.0711C41.5384 14.6805 41.5384 14.0474 41.9289 13.6569L47.5858 8L41.9289 2.34315C41.5384 1.95262 41.5384 1.31946 41.9289 0.928932C42.3195 0.538408 42.9526 0.538408 43.3431 0.928932L49.7071 7.29289ZM0 7L49 7V9L0 9L0 7Z"></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Consultancy;
