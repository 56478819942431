import { Link } from "react-router-dom";
import Img1 from "../../assets/img/about-us/about-1.png";
import Img2 from "../../assets/img/about-us/about-2.png";

import Layout from "../../components/layout/Layout";
import GetInTouch from "./GetInTouch";
import OurTeam from "./OurTeam";
import WorkingProcess from "./WorkingProcess";


const About = () => {
  return (
    <Layout>
      <section className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]">
        <h2 className="flex items-center justify-center mx-auto pt-24 lg:pt-0">
          About Us
        </h2>
      </section>

      <section className="about-us py-16 mb-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src={Img1} alt="about" />
                <img src={Img2} alt="about" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <div className="section-title v1">
                  <h6>About Us</h6>
                  <h2>BeeGulf Honey is One Iconic Brand.</h2>
                </div>
                <p>
                super health food (BeeGulfHoney) to your Tea, Salad, Oatmeal,Smoothie, Yoghurt, Chicken, or 
                enjoy the spoon or mix in a glass cup of room temperature water 
                as a great morning wellness therapy,
                 pre-workout natural energy booster or as skin glowing facemask
                </p>
                <div className="check-and-heading">
                  <ul>
                    <li>
                      <div className="icon relative border border-gray-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          className="absolute left-2 top-3"
                        >
                          <path d="M18.6 2.3 6 14.9l-4.6-4.6L0 11.7l6 6 14-14z"></path>
                        </svg>
                      </div>
                      <h6>Promotes burn and wound healing</h6>
                    </li>
                    <li>
                      <div className="icon relative border border-gray-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          className="absolute left-2 top-3"
                        >
                          <path d="M18.6 2.3 6 14.9l-4.6-4.6L0 11.7l6 6 14-14z"></path>
                        </svg>
                      </div>
                      <h6>Better for blood sugar levels than regular sugar</h6>
                    </li>
                    <li>
                      <div className="icon relative border border-gray-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          className="absolute left-2 top-3"
                        >
                          <path d="M18.6 2.3 6 14.9l-4.6-4.6L0 11.7l6 6 14-14z"></path>
                        </svg>
                      </div>
                      <h6>May help suppress coughing in children</h6>
                    </li>
                  </ul>
                </div>
                <Link to={""} className="link-anime v2 no-underline">
                  read our story
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <GetInTouch /> */}
      {/* <OurTeam /> */}
      <WorkingProcess />
    </Layout>
  );
};

export default About;
