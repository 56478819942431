import { myProductCategoryActions } from ".";
import { getAllCategoryService } from "./services";

export const getAllProductCategorysAction = (query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductCategoryActions.setIsFetching(true));
      const res = await getAllCategoryService(query);
      if (res?.status === 200) {
        dispatch(myProductCategoryActions.setAll(res));
        dispatch(myProductCategoryActions.setIsFetching(false));
      }
      dispatch(myProductCategoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
