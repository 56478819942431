import { myTestimonialActions } from ".";
import { getAllService} from "./services";

export const getAllmyTestimonialAction = (query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTestimonialActions.setIsFetching(true));
      const res = await getAllService(query);
      if (res?.status === 200) {
        dispatch(myTestimonialActions.setAll(res));
        dispatch(myTestimonialActions.setIsFetching(false));
      }
      dispatch(myTestimonialActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

