import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllService = async (query: string) => {
  return HttpRequest.get(`${SERVER_URL}/product${query}`);
};

export const getOneService = async (id: string) => {
  return HttpRequest.get(`${SERVER_URL}/product/one/${id}`);
};
