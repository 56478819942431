import React, { useState } from "react";
import Logo from "../../assets/img/logo/LOGO.png";
import { Link } from "react-router-dom";
import { menuItems } from "../../assets/data/dataItems";
import { AiFillShopping } from "react-icons/ai";
import ShoppingCard from "../card/ShoppingCard";
import { useMediaQuery } from "@mui/material";
import { Modal } from "antd";
import AppointmentForm from "../form/AppointmentForm";
import { Shop } from "@material-ui/icons";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const handleDropDown = () => setOpenMenu(!openMenu);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 800px)");

  const storedCartData = localStorage.getItem("cart");
  const cartItems = storedCartData ? JSON.parse(storedCartData) : [];


  const totalQnty = cartItems
    ?.map((data: any) => data?.quantity)
    ?.reduce((total: number, quantity: number) => (total += quantity), 0);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <div className="menu-bar v2 shadow-lg">
        <div className="">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2 col-xl-2">
                <div className="menu-left flex justify-between ">
                  <button className="h-menu-btn ml-2" onClick={handleDropDown}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                  <div className="menu-logo flex flex-wrap-col" style={{ justifyContent: 'center', flexWrap: 'wrap' }} >
                    <div className="flex" style={{ width:'100%' }}>
                      <Link to={"/"} style={{ textDecoration: 'none' }}>
                        {" "}
                        <img src={Logo} alt="Logo" className="w-[50px] h-[80px]" />
                      </Link>
                    </div>
                    <div className="flex" style={{ width:'100%' }}><small style={{ color: 'black', fontWeight: 'bold', fontSize: 9 }} className="md:50 sm:max-xl:100 ">Life is Sweet with BeeGulf Honey</small>
                    </div>


                  </div>

                  {isMobile && (
                    <div className="w-10 rounded-full h-10 bg-[#fcc31ad5] relative">
                      <div
                        className="w-[2.50rem] h-[2.40rem] rounded-full  bg-white flex items-center justify-center cursor-pointer"
                        onClick={showDrawer}
                      >
                        <AiFillShopping className="w-6 h-6" />
                        {totalQnty > 0 && (
                          <div className="max-w-6 text-[10px] max-h-6 bg-[#fcc41a] absolute -top-2 -right-1 rounded-full">
                            <span className="p-1">{totalQnty}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-10 d-none d-xl-block">
                <div className="flex items-center space-x-8">
                  <nav className="main-menu">
                    <ul className="ul-menu mb-0">
                      {menuItems.map((menuItem, index) => (
                        <li key={index}>
                          <Link
                            to={menuItem.link}
                            className="text-gray-950 text-xs hover:text-gray-700"
                          >
                            {menuItem.label}
                          </Link>
                          {menuItem.subItems && menuItem.subItems.length > 0 && (
                            <ul className="pl-0">
                              {menuItem.subItems.map((subItem, subIndex) => (
                                <li key={subIndex}>
                                  <Link
                                    to={subItem.link}
                                    className="text-gray-950 text-xs hover:text-gray-700"
                                  >
                                    {subItem.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}  
                    </ul>
                  </nav>
                  <div className="col-lg-0">
                        <div>
                          <button className="md:max-xl:show"
                            onClick={showModal}
                            id="btn" style={{
                              backgroundColor: '#fcc41a',
                              color:'black',
                              position: "relative",
                              paddingTop: 3,
                              paddingBottom: 3,
                              paddingLeft: 10,
                              paddingRight: 8,
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: 4,
                              textTransform: 'uppercase',
                              borderRadius: 5,
                              
                            }}
                          >
                            Make Appointment
                          </button>
                        </div>
                      </div>
                </div>
                <div className="w-10 rounded-full h-10 bg-[#fcc31ad5] absolute bottom-[-20px] right-20 top-8">
                  <div
                    className="w-[2.50rem] h-[2.40rem] rounded-full  bg-white flex items-center justify-center cursor-pointer"
                    onClick={showDrawer}
                  >
                    <AiFillShopping className="w-6 h-6" />
                    {totalQnty > 0 && (
                      <div className="max-w-6 text-[10px] max-h-6 bg-[#fcc41a] absolute ">
                        <span className="p-1">{totalQnty}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav
          className={
            !openMenu
              ? "hidden"
              : "absolute bg-white w-full translate-x-3 justify-end border-b pb-4 font-poppins right-4 z-10 transition-all duration-500 ease-in lg:hidden main-menu"
          }
        >
          <ul>
            {menuItems.map((menuItem, index) => (
              <li key={index}>
                <Link
                  to={menuItem.link}
                  className="text-gray-950 text-xs hover:text-gray-700"
                >
                  {menuItem.label}
                </Link>
                {menuItem.subItems && menuItem.subItems.length > 0 && (
                  <ul className="pl-0">
                    {menuItem.subItems.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.link}
                          className="text-gray-950 text-xs hover:text-gray-700"
                        >
                          {subItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <div className="col-lg-0">
              <div>
                <button className="sm:md-xl:hidden"
                  onClick={showModal}
                  id="btn" style={{
                    backgroundColor: '#fcc41a',
                    position: "relative",
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: 10,
                    paddingRight: 8,
                    fontWeight: 700,
                    fontSize: 14,
                    lineHeight: 4,
                    textTransform: 'uppercase',
                    borderRadius: 5,
                    //  zIndex: 1
                  }}
                >
                  Make Appointment
                </button>
              </div>
            </div>
          </ul>
        </nav>
        <div id="sticky-banner" className="fixed mt-0 left-0 z-25  z--1 flex justify-between w-full h-2 p-3 border-b border-yellow-500 bg-yellow-500 dark:bg-yello-500 dark:border-yellow-500">
          <div className="flex items-center mx-auto h-5 sm:max-xl:50px">
            <p className="flex items-center text-sm font-normal text-black dark:text-black font-bold">
              <span className="inline-flex p-1 mr-3 bg-gray-200 rounded-full dark:bg-gray-600 w-6 h-6 items-center justify-center">
                <svg className="w-3 h-3 text-black dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                  <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
                </svg>
                <span className="sr-only"></span>
              </span>
              <span > high-quality, all-natural bee products <a className="inline font-medium text-black underline dark:text-black no-underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline"><Link to="/shop" className="text-black">Shop</Link></a></span>
            </p>
          </div>
          <div className="flex items-center">
            <button data-dismiss-target="#sticky-banner" type="button" className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <ShoppingCard showDrawer={open} onClose={onClose} />
      <Modal title="BooK For Your Appointment" open={isModalOpen} footer={null} onCancel={handleCancel}>
        <AppointmentForm onCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default Navbar;
