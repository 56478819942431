import React, { useState } from 'react';

const Tabs = ({ tabs}:{tabs:any}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleClick = (index:any) => {
    setActiveTab(index);
  };

  return (
    <div className="bg-gold p-4 ">
      <div className="flex  items-center">
        {tabs.map((tab:any, index:any) => (
          <button
            key={index}
            className={`${
              activeTab === index
                ? 'bg-yellow-500 text-black'
                : 'bg-yellow-200 text-black'
            } px-4 py-2 rounded-l focus:outline-none items-center ml-8`}
            onClick={() => handleClick(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="mt-0.5 p-4 bg-white-100 rounded-b">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
