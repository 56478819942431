import React, { useState } from 'react';
import VideoPlayModel from '../models/VideoPlayModel';

const VideoPlayer: React.FC = () => {
  const [isVideoVisible, setVideoVisible] = useState(false);

  const toggleVideo = () => {
    console.log("VIDEO:::")
    setVideoVisible(!isVideoVisible);
  };

  return (
    <div>
      <button
        className="play-btn venobox"
        data-background="assets/img/banner/v1/play-img.jpg"
        data-vbtype="video"
        data-maxwidth="800px"
        onClick={toggleVideo}
        style={{margin:'0px 0px 170px 0px'}}
      >

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M26.17 12.37 9 2.45A3.23 3.23 0 0 0 7.38 2 3.38 3.38 0 0 0 4 5.38v21.29a3.33 3.33 0 0 0 5.1 2.82l17.19-10.86a3.65 3.65 0 0 0-.12-6.26z"></path>
        </svg>
        

      </button>
      {isVideoVisible && (
        <VideoPlayModel videoLink='https://www.youtube.com/embed/wcwArSHvGBg?si=LEWV0b4l2TUjMPxt' closeModal={toggleVideo}></VideoPlayModel>
      )}
    </div>
  );
};

export default VideoPlayer;
