export const getActivePrice = (prices: any) => {
  return prices?.find((price: any) => price.isActive)?.value;
};

export const getTotalPrice = (list: any) => {
  return list
    ?.map(
      (item: any, _index: number) =>
        item?.payment?.amount +
        (item?.payment?.amount * item?.payment?.extendedWarranty) / 100
    )
    ?.reduce((total: any, price: any) => (total += parseInt(price)), 0);
};
