import React, { useEffect } from "react";
import Layout from "../../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneBlogPostAction } from "../../../store/blogs/actions";
import moment from "moment";
import parse from "html-react-parser";
import { Spin } from "antd";

const BlogDetails = () => {
  const { blogPost } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { blogId } = useParams();
  useEffect(() => {
    blogId && getOneBlogPostAction(blogId)(dispatch);
  }, [blogId, dispatch]);

  return (
    <Layout>
      {blogPost?.selected ? (
        <>
          <section
            className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]"
            data-background="assets/img/breadcum/bg.jpg"
          >
            <h2 className="flex items-center justify-center mx-auto pt-24 lg:pt-0 capitalize">
              {blogPost?.selected?.category?.categoryName}
            </h2>
          </section>
          <div className="w-full min-h-screen">
            <div className="w-full md:w-[75%] lg:w-3/5 py-4 px-4 md:px-0 items-center flex mx-auto min-h-44 relative">
              <div>
                <h4 className="font-bold mb-14 md:mb-6">
                  {blogPost?.selected?.title}
                </h4>
                <div className="absolute bottom-0 left-0 flex flex-col md:flex-row md:items-center md:space-x-4">
                  <div className="flex items-center space-x-2 px-8 md:px-0">
                    <img
                      src={blogPost?.selected?.createdBy?.picture}
                      alt=""
                      className="w-8 h-8 rounded-full object-cover"
                    />
                    <span>
                      {blogPost?.selected?.createdBy?.names?.split(" ")[0]}
                    </span>
                  </div>
                  <span className="px-8 md:px-0">
                    {" "}
                    {moment(blogPost?.selected?.createdAt).format(
                      "dddd, Do MMMM YYYY"
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full md:w-[75%] items-center flex mx-auto min-h-32 pt-10 relative">
              <div className="w-full">
                <img
                  src={blogPost?.selected?.picture}
                  alt=""
                  className="w-full h-[600px] object-cover md:rounded-lg"
                />
              </div>
            </div>
            <div className="flex flex-col mx-auto items-center">
              <div className="w-full md:w-[75%] py-4 px-2.5">
                <span className="text-justify">
                  {parse(blogPost?.selected?.content?.toString())}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-full flex items-center justify-center mx-auto h-44 pt-32">
          <Spin />
        </div>
      )}
    </Layout>
  );
};

export default BlogDetails;
