import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import { getAllBlogPostAction } from "../../store/blogs/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { searchValue } from "../../utils/setColor";

const Blog = () => {
  const { blogPost } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllBlogPostAction("?isPublished=true")(dispatch);
  }, [dispatch]);

  return (
    <Layout>
      <main>
        <section
          className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]"
          data-background="assets/img/breadcum/bg.jpg"
        >
          <h2 className="flex items-center justify-center mx-auto pt-24 lg:pt-0">
            Blog
          </h2>
        </section>
        <div className="w-full min-h-screen">
          <div className="w-full md:w-4/5 py-4 flex items-center mx-auto px-2">
            <h1
              className="capitalize"
              style={{ borderBottom: "2px solid #fcc41a" }}
            >
              Featured posts
            </h1>
          </div>
          <div className="flex items-center justify-center mx-auto pb-10">
            <div className="w-full md:w-4/5 h-full space-y-6 px-2">
              {blogPost?.all?.data?.map((el: any) => (
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                  <div className="w-full md:w-60 min-h-32">
                    <img
                      src={`${
                        el?.picture ??
                        " https://arun-gatsby.netlify.app/static/de1a500b08035a4593ffa3e57faaf427/123fa/foot-platter.webp"
                      }`}
                      alt=""
                      className="object-cover w-full h-[200px] md:w-60 md:h-[200px] rounded-md"
                    />
                  </div>
                  <div className="flex-1 md:h-[12.5rem] h- flex flex-col relative">
                    <div>
                      <Tag
                        color={searchValue(el?.category?.categoryName)}
                        className="mb-2.5 rounded-md text-center capitalize"
                      >
                        {el?.category?.categoryName}
                      </Tag>
                      <Link
                        to={`/blogs/view/${el?._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <h3 className="line-clamp-2 hover:underline">
                          {" "}
                          {el?.title}
                        </h3>
                      </Link>
                      <p className="line-clamp-2">{el?.description}</p>
                    </div>
                    <span className="absolute bottom-0 left-0">
                      {" "}
                      {moment(el?.updatedAt)?.format("MMMM Do YYYY")}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Blog;
