import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import VideoPlayer from "./VideoPlay";





const TopSection: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(min-width: 768px)");
  return (
    <section className="banner v1 top-spach relative -mt-20">
      <span className="shape-1 bg-[url('assets/img/banner/v1/shap-1.png')]"></span>
      {isTablet && (
        <span className="shape-2 bg-[url('assets/img/banner/v1/shap-2.png')]"></span>
      )}
      {isMobile && (
        <span className="shape-2 bg-[url('assets/img/banner/v1/shap-2.png')]"></span>
      )}
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 xbg-red-500">
            <div className="banner-left">
              <div className="banner-content">
                <h2>Premiun Grade, Natural honey!</h2>
                <p>
                Beyond a sweet treat, our honey is a living testament to your well-being. 
                It comes from the nectar of plants from the remote areas in forests of 1000 hills of Rwanda.
                </p>
                <div className="list-service">
                  <ul>
                    <li>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 511.985 511.985"
                        >
                          <path d="M500.088 83.681c-15.841-15.862-41.564-15.852-57.426 0L184.205 342.148 69.332 227.276c-15.862-15.862-41.574-15.862-57.436 0-15.862 15.862-15.862 41.574 0 57.436l143.585 143.585c7.926 7.926 18.319 11.899 28.713 11.899 10.394 0 20.797-3.963 28.723-11.899l287.171-287.181c15.862-15.851 15.862-41.574 0-57.435z"></path>
                        </svg>
                      </span>
                      <h6>Natural energy booster</h6>
                    </li>
                    <li>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 511.985 511.985"
                        >
                          <path d="M500.088 83.681c-15.841-15.862-41.564-15.852-57.426 0L184.205 342.148 69.332 227.276c-15.862-15.862-41.574-15.862-57.436 0-15.862 15.862-15.862 41.574 0 57.436l143.585 143.585c7.926 7.926 18.319 11.899 28.713 11.899 10.394 0 20.797-3.963 28.723-11.899l287.171-287.181c15.862-15.851 15.862-41.574 0-57.435z"></path>
                        </svg>
                      </span>
                      <h6>Strengthens immunine system</h6>
                    </li>
                    <li>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 511.985 511.985"
                        >
                          <path d="M500.088 83.681c-15.841-15.862-41.564-15.852-57.426 0L184.205 342.148 69.332 227.276c-15.862-15.862-41.574-15.862-57.436 0-15.862 15.862-15.862 41.574 0 57.436l143.585 143.585c7.926 7.926 18.319 11.899 28.713 11.899 10.394 0 20.797-3.963 28.723-11.899l287.171-287.181c15.862-15.851 15.862-41.574 0-57.435z"></path>
                        </svg>
                      </span>
                      <h6>Natural food sweetener</h6>
                    </li>
                  </ul>
                </div>

                <div className="all-btns">
                  <ul>
                    <li>
                      <Link
                        to={"contact"}
                        className="link-anime v2 no-underline"
                      >
                        contact us
                      </Link>
                    </li>
                    <li>
                      <Link to={"shop"} className="link-anime v2 no-underline">
                        Shop Now
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="rount-profile-bg bg-[url(assets/img/banner/v1/profile.jpg)] lg:bg-[url(assets/img/banner/v1/profile.jpg)] w-[120] h-[100]"
              data-background="assets/img/banner/v1/profile.jpg"
            >
            <VideoPlayer/>
              
            </div>

          </div>
        </div>
      </div>
      <a
        href="#our_services"
        className="scroll-down-1 absolute left-1/2 lg:left-20 transform -translate-x-1/2 bottom-8 required:"
      >
        <svg
          viewBox="0 0 16 34"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute left-3 top-4"
        >
          <path d="M7.29289 33.5895C7.68342 33.98 8.31658 33.98 8.70711 33.5895L15.0711 27.2255C15.4616 26.835 15.4616 26.2018 15.0711 25.8113C14.6805 25.4208 14.0474 25.4208 13.6569 25.8113L8 31.4682L2.34315 25.8113C1.95262 25.4208 1.31946 25.4208 0.928932 25.8113C0.538408 26.2018 0.538408 26.835 0.928932 27.2255L7.29289 33.5895ZM7 0.490234L7 32.8824H9L9 0.490234L7 0.490234Z" />
        </svg>
      </a>
    </section>
  );
};

export default TopSection;
