import React, { useState, useEffect } from "react";
import { sendFeedbackAction } from "../../store/feedback/actions";
import { useDispatch } from "react-redux";
import EmailSend from "../../pages/contact/Email";
import { notification } from "antd";
import { sendOrderCardAction } from "../../store/ordercard/actions";
interface FormData {
  name: string;
  email: string;
  phone: string;
  location: string;
  

}

const OrderForm = (props:any) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    location: "",
  }); 
 console.log(props.cartItems,"simba");
  const handleSendOrderCard = async () => {

    if (
      formData?.name !== "" &&
      formData?.email !== "" &&
      formData?.phone !== "" &&
      formData?.location !== ""
      )
       {
        await sendOrderCardAction({...formData,product:props.cartItems,
          cartTotal:props.cartTotal,
          isNotify: true,
          notification: {  
            message: "Thanks you", 
            role: ['user', 'admin','manager'] ,
            phone:formData?.phone, 
            email:formData?.email,
            name:formData?.name,
            location:formData?.location,



            }
        })(dispatch);

      
    } else { 
      notification.error({ message: "Please make sure you fill all the field " });
    }

    setFormData({
      name: "",
      email: "",
      phone: "",
      location: "",
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  return (
    <div className="message-form">
      <ul>
        <li>
          {/* <h5>Your Name</h5> */}
          <input
            type="name"
            name="name"
            placeholder="Your Name"
            onChange={handleChange}
            value={formData.name}
            required
          />
        </li>
        <li>
          {/* <h5>Your Email</h5> */}
          <input
            type="email"
            name="email"
            placeholder="beegulf@example.com"
            onChange={handleChange}
            value={formData.email}
            required
          />
        </li>
        <li>
          
          <input
            type="number"
            name="phone"
            placeholder="+(250) 7889-4744"
            onChange={handleChange}
            value={formData.phone}
            required
          />
        </li>
        <li>
          {/* <h5>Address</h5> */}
          <input
            type="text"
            name="location"
            placeholder="Distric-Sector-Cell-Village-Street"
            onChange={handleChange}
            value={formData.location}
            required
          />
        </li>
      </ul>
      <button className="btn-anime v2 ml-10px" onClick={handleSendOrderCard}>
        ORDER NOW
      </button>
      <p className="ajax-response"></p>
    </div>
  );
};

export default OrderForm;
