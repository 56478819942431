interface honybenefitData {
  key: number;
  content: string;
}
interface nutribenefitType {
  key: number;
  content: string;
}

interface faqType {
  key: number;
  title: string;
  content: string;
}

interface consultancyType {
  key: number;
  content: string;
}

interface trainingType {
  key: number;
  content: string;
}

interface trainingTypeMain{
  key: number;
  content: string;
}

interface trainingTypeMin{
  key: number;
  content: string;
}

interface trainingTypeText{
  key: number;
  content: string;
}
interface nutribenefitType{
  key: number;
  content: string;
}

interface historyType {
  key: number;
  title: string;
  content: string;
  year: number;
}

export const honybenifitData: honybenefitData[] = [
  { key: 1, content: "Better for blood sugar levels than regular sugar." },
  { key: 2, content: "1 teaspoon pure vanilla extract.." },
  { key: 3, content: "1 cup whole milk, heated to 110°F." },
  { key: 4, content: "3 large egg yolks." },
  { key: 5, content: "2 to 2 1/2 cups (320 to 400 grams) bread flour." },
  { key: 6, content: "2 1/2 teaspoon table salt." },
];

export const nutribenefitData: nutribenefitType[] = [
  { key: 1, content: "	To get relief from cough & cold." },
  { key: 2, content: "	For promotion of healthy digestive system" },
  { key: 3, content: "	Helps heal wounds faster" },
  { key: 4, content: "	Boosts energy" },
  { key: 5, content: "	Natural supplier of multivitamins" },
  { key: 6, content: "	Strengthens immune system " },
];

export const faqTypeData: faqType[] = [
  {
    key: 1,
    title: "How can I pay for order?",
    content:
      " Products are ordered using the online form or by telephone. The Customer should complete the Profile with the general particulars required for the payment and delivery of the chosen Products and will be notified by e-mail after his/her order is fulfilled. After you select a Product, you may click its details and learn everything about it or have a better look at it and, if you want to buy it, just click the shopping cart and it will immediately be added to it.",
  },
  {
    key: 2,
    title: " What payment methods exist in your company?",
    content:
      " Products are ordered using the online form or by telephone. The Customer should complete the Profile with the general particulars required for the payment and delivery of the chosen Products and will be notified by e-mail after his/her order is fulfilled. After you select a Product, you may click its details and learn everything about it or have a better look at it and, if you want to buy it, just click the shopping cart and it will immediately be added to it.",
  },
  {
    key: 3,
    title: "Is it possible to pay by credit card?",
    content:
      " Products are ordered using the online form or by telephone. The Customer should complete the Profile with the general particulars required for the payment and delivery of the chosen Products and will be notified by e-mail after his/her order is fulfilled. After you select a Product, you may click its details and learn everything about it or have a better look at it and, if you want to buy it, just click the shopping cart and it will immediately be added to it.",
  },
  {
    key: 4,
    title: "Where and how can I exchange or refund?",
    content:
      " Products are ordered using the online form or by telephone. The Customer should complete the Profile with the general particulars required for the payment and delivery of the chosen Products and will be notified by e-mail after his/her order is fulfilled. After you select a Product, you may click its details and learn everything about it or have a better look at it and, if you want to buy it, just click the shopping cart and it will immediately be added to it.",
  },
  {
    key: 5,
    title: "How can I get a promotional code?",
    content:
      " Products are ordered using the online form or by telephone. The Customer should complete the Profile with the general particulars required for the payment and delivery of the chosen Products and will be notified by e-mail after his/her order is fulfilled. After you select a Product, you may click its details and learn everything about it or have a better look at it and, if you want to buy it, just click the shopping cart and it will immediately be added to it.",
  },
  {
    key: 6,
    title: "How can I cancel the order?",
    content:
      " Products are ordered using the online form or by telephone. The Customer should complete the Profile with the general particulars required for the payment and delivery of the chosen Products and will be notified by e-mail after his/her order is fulfilled. After you select a Product, you may click its details and learn everything about it or have a better look at it and, if you want to buy it, just click the shopping cart and it will immediately be added to it.",
  },
];

export const consultancyData: consultancyType[] = [
  { key: 1, content: "Better for blood sugar levels than regular sugar." },
  { key: 2, content: "1 teaspoon pure vanilla extract.." },
  { key: 3, content: "1 cup whole milk, heated to 110°F." },
  { key: 4, content: "3 large egg yolks." },
  { key: 5, content: "2 to 2 1/2 cups (320 to 400 grams) bread flour." },
  { key: 6, content: "2 1/2 teaspoon table salt." },
];

export const trainingData: trainingType[] = [
  { key: 1, content: "An understanding of honeybee biology and behaviour." },
  { key: 2, content: "Learning about the ecological contribution of bees in relation to biodiversity continuity." },
  { key: 3, content: "Learning the skills of working with the honey bee colony." },
  { key: 4, content: "Getting the information and skills needed to manage a honey bee colony." },
  { key: 5, content: "Earning the essential skills of managing an apiary." },
  { key: 6, content: "Getting the experience of how to harvest all the bee products." },
];

export const trainingDataMain: trainingTypeMain[] = [
  { key: 1, content: "	Lectures" },
  { key: 2, content: "	Practical indoor and outdoor" },
  { key: 3, content: "	Discussions" },
  { key: 4, content: "  Quiz ." },
];

export const trainingDataMin: trainingTypeMin[] = [
  { key: 1, content: "	Construction of 3 modern apiaries ." },
  { key: 2, content: "	Equipping your apiaries with modern beekeeping equipment, beehives," },
  { key: 3, content: "	Putting the bees in your beehives." },
  { key: 4, content: "	Technical follow-up ." },
  { key: 5, content: "	Design the customised plan about the  sustainability plan of your beekeeping project ." },
  { key: 6, content: "Read More." },
];

export const trainingDataText: trainingTypeText[] =[
  { key: 1, content: "	Construction of 3 modern apiaries ." },
  { key: 2, content: "	General introduction to queen bee queen bee and lifecycle of bees," },
  { key: 3, content: "	Learning about the ecological contribution of bees in relation to biodiversity continuity." },
  { key: 4, content: "	Learning the skills of working with the honey bee colony." },
  { key: 5, content: "	Getting the information and skills needed to manage a honey bee colony." },
  { key: 6, content: "	Earning the essential skills of managing an apiary." },
  { key: 7, content: "	Bee Friendly environment and mellifluous plants" },
  { key: 8, content: "	To understand the fundamentals of beekeeping for the purpose of profit making," },
  { key: 9, content: "	To prepare the participants for subsequent trainings." },
  { key: 10, content: "	How to multiply bee colonies, introduction of a new queen in a hive." },
  { key: 11, content: "	Practical exercises on bee pollen tablets making, Soaps candles, Wraps, lip balms, and body lotions " },
];

export const storyData: historyType[] = [
  {
    key: 1,
    title: "Honey Is a Nutritious, Delicious..",
    content:
      " Honey bees stockpile honey in the hive. Within the hive is a structure made from wax called honeycomb. The honeycomb is made up of hundreds or thousands of hexagonal cells, into which the bees regurgitate honey for storage.",
    year: 1980,
  },
  {
    key: 2,
    title: "Future Goal With Honey",
    content:
      " Honey bees stockpile honey in the hive. Within the hive is a structure made from wax called honeycomb. The honeycomb is made up of hundreds or thousands of hexagonal cells, into which the bees regurgitate honey for storage.",
    year: 1989,
  },
  {
    key: 3,
    title: "A Honey Bee Calyx of Goldenrod.",
    content:
      " Honey bees stockpile honey in the hive. Within the hive is a structure made from wax called honeycomb. The honeycomb is made up of hundreds or thousands of hexagonal cells, into which the bees regurgitate honey for storage.",
    year: 2000,
  },

  {
    key: 4,
    title: "Honey Harvest..",
    content:
      " Honey bees stockpile honey in the hive. Within the hive is a structure made from wax called honeycomb. The honeycomb is made up of hundreds or thousands of hexagonal cells, into which the bees regurgitate honey for storage.",
    year: 2010,
  },

  {
    key: 5,
    title: "Modern uses for Honey..",
    content:
      " Honey bees stockpile honey in the hive. Within the hive is a structure made from wax called honeycomb. The honeycomb is made up of hundreds or thousands of hexagonal cells, into which the bees regurgitate honey for storage.",
    year: 2015,
  },

  {
    key: 6,
    title: "Honey Health Properties..",
    content:
      " Honey bees stockpile honey in the hive. Within the hive is a structure made from wax called honeycomb. The honeycomb is made up of hundreds or thousands of hexagonal cells, into which the bees regurgitate honey for storage.",
    year: 2022,
  },
];
