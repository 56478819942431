import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllCategoryService = async (query: string) => {
  const response = HttpRequest.get(`${SERVER_URL}/apitourism/category/${query}`);
  
  return (
    response
   
    );
};
