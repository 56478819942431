import React from "react";
import { Link } from "react-router-dom";

const NewLetter = () => {
  return (
    <div className="news-letter bg-cover-center bg-[url('assets/img/footer/app-bg.jpg')]">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="section-title shap-green v1">
            <h6>Newsletter</h6>
            <h2>Get Weekly Updates On New Products!</h2>
          </div>
        </div>
        <div className="offset-xl-1 col-lg-6 col-xl-5">
          <div className="subscribe-bar">
            <form action="#" className="flex flex-col md:flex-row items-center">
              <input type="email" placeholder="Enter your e-mail" />
              <button type="submit" className="btn-anime v1 mb-3">
              <Link to={"/contact"} className="no-underline" style={{color:'black'}}>
              subscribe
          </Link>
                
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLetter;
