import { myAppointmentActions } from ".";
import { createAppointmentService } from "./services";

export const createAppointmentAction = (data: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAppointmentActions.setIsFetching(true));
      const res = await createAppointmentService(data);
      if (res?.status === 201) {
        dispatch(myAppointmentActions.setNew(res));
        dispatch(myAppointmentActions.setIsFetching(false));
      }
      dispatch(myAppointmentActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

