import { Routes, Route } from "react-router-dom";
import LandingPage from "../components/landing";
import Shop from "../pages/shop";
// import Service from "../pages/service/Service";
// import Blog from "../pages/blogs/Index";
import BlogPost from "../pages/blogPost/Blog";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import HoneyBenefits from "../pages/benefit/honybenefit";
import Nutritional from "../pages/benefit/Nutribenefit";
import Training from "../pages/service/training/Training";
import Consultancy from "../pages/service/consultancy/Consultancy";
import Apitourism from "../pages/service/apitourism/Apitourism";
import ServiceDetails from "../components/view/Service";
import ProductDetails from "../components/view/product/ProductDetails";
import BlogDetails from "../components/view/blog/BlogDetails";
import ApitourismDetails from "../components/view/apitourism/ApitourismDetails";
import ProductInfo from "../pages/productCard/PersonalInfo";
import PersonalInfo from "../pages/productCard/PersonalInfo"
import Ordercard from "../store/ordercard";
function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/shop" element={<Shop />} />
      <Route
        path="/shop/:productName/:productId"
        element={<ProductDetails />}
      />
      {/* <Route path="/service" element={<Service />} /> */}
      <Route path="/service/name/1234" element={<ServiceDetails />} />
      {/* <Route path="/blog" element={<Blog />} /> */}
      <Route path="/blogs" element={<BlogPost />} />
      <Route path="/blogs/view/:blogId" element={<BlogDetails />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/honey/benefits" element={<HoneyBenefits />} />
      <Route path="/honey/benefits" element={<HoneyBenefits />} />
      <Route path="/honey/nutrition" element={<Nutritional />} />
      <Route path="/training" element={<Training />} />
      <Route path="/consultancy" element={<Consultancy />} />
      <Route path="/apitourism" element={<Apitourism />} />
      <Route path="/apitourism/view/:id" element={<ApitourismDetails />} />
      <Route path="/productCard" element={<PersonalInfo onPersonalInfoSubmit={function (name: string, email: string, address: string, phone: string): void {
        throw new Error("Function not implemented.");
      } }/>} />
      <Route path="/productCard" element={<ProductInfo onPersonalInfoSubmit={function (name: string, email: string, address: string, phone: string): void {
        throw new Error("Function not implemented.");
      } }/>} />


    </Routes>
  );
}

export default App;
