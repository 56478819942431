import React, { useState } from 'react';
import Layout from "../../components/layout/Layout";
import OrderForm from "../../components/form/OrderForm";

interface PersonalInfoProps {
  onPersonalInfoSubmit: (name: string, email: string, address: string, phone: string) => void;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ onPersonalInfoSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onPersonalInfoSubmit(name, email, address, phone);


  };
  const storedCartData = localStorage.getItem("cart");
  const cartItems = storedCartData ? JSON.parse(storedCartData) : [];


  const totalQnty = cartItems
    ?.map((data: any) => data?.quantity)
    ?.reduce((total: number, quantity: number) => (total += quantity), 0);

  const calculateItemTotal = (item: any) => {
    const price = parseFloat(item.price);
    const quantity = parseInt(item.quantity, 10);
    return price * quantity;
  };


  // Calculate the overall cart total
  const cartTotal = cartItems.reduce((total: any, item: any) => {
    return total + calculateItemTotal(item);
  }, 0);

  return (
    <Layout>
      <section className="send-message py-16">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left-content">
                <div className="section-title v1">
                  <h6>Send Your Order</h6>
                  <h2>Just Have a Quick Order</h2>
                </div>
                <div className="socials-links-box">
                 <ul><li>{cartItems?.map((el: any, index: any) => (
                    <li key={index}>
                      <div className="flex items-center justify-between pr-2 py-1">
                        <div className="flex items-center space-x-4">
                          <img
                            src={el?.picture}
                            alt="product"
                            className="h-40 w-30 object-cover"
                          />
                          <div className="">
                            <h6>{el?.name}</h6>
                            <div className="">
                              <span className="">{`${el?.quantity} x `}</span>
                              <span className="">{`RWF ${el?.price}`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}</li></ul>
                  
                  <div>
                    <h6 className="font-[900] text-[#fcc41a] text-xl mt-2">Total</h6>
                    <span className="prices text-[#fcc41a] text-xl mt-2">
                      {`RWF ${cartTotal?.toLocaleString()}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <OrderForm cartItems ={cartItems} 
                         cartTotal={cartTotal}/>
            </div>
          </div>
        </div>
      </section></Layout>
  );

};

export default PersonalInfo;
