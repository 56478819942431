import React from 'react';
import { Button, DatePicker, Form, Input, Select, notification } from 'antd';
import { createAppointmentAction } from '../../store/appointment/actions';
import { useDispatch } from 'react-redux';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};


const AppointmentForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    await createAppointmentAction({
      ...values,
      isNotify: true,
      notification: { 
        action: "Well Received", 
        message: "Thanks you", 
        role: ['user', 'admin'] ,
        phone:values?.Telephone, 
        email:values?.Email,
        name:values?.Names,
        description:values?.Description}
    })(dispatch);
    props.onCancel()
    form.resetFields()
    notification.success({ message: "Successful Sent!" });
  };
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{ maxWidth: 600, }}
    >
      <div style={{ marginTop: '50px', marginLeft: '50px', width: '80%' }}>
        <Form.Item name="Names" rules={[{ required: true }]}>
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item name="Email" rules={[{ required: true }]}>
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item name="Telephone" style={{ alignItems: 'center' }} rules={[{ required: true }]}>
          <Input placeholder="Telephone number" />
        </Form.Item>

        <Form.Item name="Date" rules={[{ required: true }]}>
          <DatePicker placeholder='Choose your time' />
        </Form.Item>

        <Form.Item name={'Description'} rules={[{ required: true }]}>
          <TextArea placeholder='Description' rows={4} />
        </Form.Item>
      </div>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.Names !== currentValues.Names}
      >
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button style={{ backgroundColor: 'gold', alignItems: 'center', width: '120px', height: '70px', fontFamily: 'bold', fontSize: '10' }} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
export default AppointmentForm;