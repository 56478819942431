import React from "react";
import FAQ from "./FAQ";
import Services from "./Services";
import Testimonial from "./Testimonial";
import Product from "./Product";
import DownLoadApp from "./DownLoadApp";
import WhyUs from "./WhyUs";
import OurService from "./OurService";
import TopSection from "./TopSection";
import Layout from "../layout/Layout";

const LandingPage = () => {
  return (
    <Layout>
      <TopSection />
      <OurService />
      <WhyUs />
      <DownLoadApp />
      <Product />
      <Services />
      <Testimonial />
      <FAQ />
    </Layout>
  );
};

export default LandingPage;
