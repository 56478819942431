import React, { useState } from "react";

const Search = (props: any) => {
  const handleInputChange = (e: any) => {
    props?.onSearch(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  return (
    <div className="widget search-widget">
      <h4>Search</h4>
      <form action="#" onSubmit={props?.handleSearch}>
        <input
          type="search"
          placeholder="Search your keyword"
          value={props?.searchKey}
          onChange={handleInputChange}
        />
        <button type="submit">
          <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.8032 18.4474L14.6837 13.3279C15.8808 11.7851 16.445 9.84412 16.2614 7.90001C16.0777 5.9559 15.16 4.15486 13.6952 2.86357C12.2303 1.57227 10.3283 0.887805 8.37654 0.949516C6.42475 1.01123 4.56984 1.81448 3.18947 3.19573C1.80909 4.57699 1.00702 6.4324 0.946556 8.38423C0.886087 10.3361 1.57176 12.2376 2.86399 13.7016C4.15622 15.1657 5.95784 16.0822 7.90207 16.2646C9.8463 16.447 11.787 15.8816 13.329 14.6835L18.4481 19.8025C18.6288 19.9771 18.8709 20.0737 19.1222 20.0715C19.3735 20.0693 19.6138 19.9685 19.7915 19.7908C19.9692 19.6131 20.07 19.3728 20.0722 19.1215C20.0743 18.8702 19.9777 18.6282 19.8032 18.4474ZM4.56625 12.6974C3.6255 11.7568 3.03999 10.5191 2.9095 9.19525C2.779 7.87136 3.11159 6.54317 3.8506 5.43701C4.5896 4.33085 5.6893 3.51515 6.9623 3.12891C8.2353 2.74266 9.60285 2.80977 10.8319 3.31879C12.061 3.82781 13.0755 4.74726 13.7027 5.92046C14.3299 7.09366 14.5308 8.44802 14.2713 9.75278C14.0118 11.0575 13.308 12.232 12.2797 13.0759C11.2513 13.9199 9.96219 14.3812 8.63188 14.3812C7.10718 14.3804 5.64506 13.7749 4.56625 12.6974Z"></path>
          </svg>
        </button>
      </form>
    </div>
  );
};

export default Search;
