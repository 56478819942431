import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "apitourism",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const myApitourismActions = mySlice.actions;

export default mySlice.reducer;
