import { myApitourismActions } from ".";
import { getAllService, getOneApiService} from "./services";

export const getAllApitourismAction = (query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await getAllService(query);
      console.log(res);
      if (res?.status === 200) {
        dispatch(myApitourismActions.setAll(res));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};



export const getOneApitourismPostAction = (id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await getOneApiService(id);
      //console.log(res);
      if (res?.status === 200) {
        dispatch(myApitourismActions.setSelected(res?.data));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
