import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";


interface categoryType {
  _id: string;
  name: string;
}

const Category = (props: any) => {
  const [isSelected, setIsSelected] = useState('all');
  const { apitourismcategory } = useSelector((state: any) => state);
  useEffect(() =>{
   
    //setIsActive(true)
    console.log(`fetching:${apitourismcategory}`)
  },[isSelected] )
  const handleOnClick = (name:string, props:any) => {
 
    setIsSelected(name)
   
    if(name==='all'){
      props?.setCategoryName('');
    }else{
      props?.setCategoryName(name);
    }
    


  }
 
  return (

          <div className="gallery-btns">
            <ul>
              
              <li>
                
                <button data-target="gallery-item" className={isSelected === 'all' ? 'active' :''} onClick={() => handleOnClick('all',props)}>
                  Show All
                </button>
              </li>
              {apitourismcategory?.all?.data?.map((item: categoryType) =>

              <li>
                <button  className={isSelected === (item?.name) ? 'active' :''} onClick={() => handleOnClick(item?.name,props)}>{item?.name}</button>
              </li>
              
              )}
            </ul>
      </div>
    
  );
};

export default Category;
