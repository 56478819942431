import { notification } from "antd";
import { myFeedbackActions } from ".";
import { sendFeedbackService } from "./services";

export const sendFeedbackAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myFeedbackActions.setIsFetching(true));
      const res = await sendFeedbackService(data);
      if (res?.status === 201) {
        dispatch(myFeedbackActions.setNew(res));
        notification.success({ message: "Appreciate Your Feedback" });
        dispatch(myFeedbackActions.setIsFetching(false));
      }
      dispatch(myFeedbackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
