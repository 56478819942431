import react from 'react';

const Apitourism = () => {

    return (
        <div className="w-full bg-white border border-black-200 rounded-lg shadow dark:bg-black-800 dark:border-black-700">
            <div id="defaultTabContent">
                <div className="show p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 ml-25" id="about" role="tabpanel" aria-labelledby="about-tab">
                    <h2 className="mb-3 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white inline-flex items-center">Api tourism</h2>
                    <p className="mb-3 text-gray-500 dark:text-gray-400">Our Api tourism packages offer immersive experiences for tourists interested in beekeeping. You gain knowledge about bees’ behaviors, family (kingdom), and history of bees in Rwanda.</p>
                    <h2 className="mb-3 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">What will you learn?</h2>
                    <p className="mb-3 text-gray-500 dark:text-gray-400">During this tourism toward bees, you wear a queen bee dress code, do api sport and learn hands-on beekeeping activities, importance of bees in agriculture and ecosystem health. Our honey from hive to your table experience will give you a deeper appreciation for the world of bees and their vital role in our environment.</p>
                </div>
            </div>
        </div>

    )

}
export default Apitourism;
