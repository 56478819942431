import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface categoryType {
  _id: string;
  name: string;
}

const Category = (props: any) => {
  const { productCategory } = useSelector((state: any) => state);
  return (
    <div className="widget categories-widget">
      <h4>Categories</h4>
      <div className="widget-categories">
        <ul>
          {productCategory?.all?.data?.map((item: categoryType) => (
            <li onClick={() => props?.setCategoryName(item?.name)}>
              <Link to={""} className="flex items-center no-underline">
                <span className="icon text-xs mt-1.5">
                  <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.1859 10.5L-0.000873566 55.6868L7.42262 63.1102L52.6094 17.9235V57.75H63.1094V0H5.35938V10.5H45.1859Z" />
                  </svg>
                </span>
                <span
                  className={`${
                    props?.categoryName === item?.name &&
                    "bg-[#fcc41a] rounded-md px-2 text-white"
                  } text text-sm`}
                >
                  {item?.name}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Category;
