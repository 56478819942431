import React, { useState, useEffect } from "react";
import { sendFeedbackAction } from "../../store/feedback/actions";
import { useDispatch } from "react-redux";
import EmailSend from "../../pages/contact/Email";
import { notification } from "antd";
interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const FeedBackForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSendFeedBack = async () => {
    if (
      formData?.name !== "" &&
      formData?.email !== "" &&
      formData?.phone !== "" &&
      formData?.message !== ""
      )
       {
        await sendFeedbackAction(formData)(dispatch);

      
    } else { 
      notification.error({ message: "Please make sure you fill all the field " });
    }

    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="message-form">
      <ul>
        <li>
          <h5>Your Name</h5>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            onChange={handleChange}
            value={formData.name}
            required
          />
        </li>
        <li>
          <h5>Your Email</h5>
          <input
            type="email"
            name="email"
            placeholder="beegulf@example.com"
            onChange={handleChange}
            value={formData.email}
            required
          />
        </li>
        <li>
          <h5>Your Phone</h5>
          <input
            type="number"
            name="phone"
            placeholder="+(250) 7889-4744"
            onChange={handleChange}
            value={formData.phone}
            required
          />
        </li>
        <li>
          <h5>Message</h5>
          <textarea
            name="message"
            placeholder="Simultaneously we had a problem"
            onChange={handleChange}
            value={formData.message}
            required
          ></textarea>
        </li>
      </ul>
      <button className="btn-anime v2" onClick={handleSendFeedBack}>
        send message
      </button>
      <p className="ajax-response"></p>
    </div>
  );
};

export default FeedBackForm;
