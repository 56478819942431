import { myProductActions } from ".";
import { getAllService, getOneService } from "./services";

export const getAllProductsAction = (query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getAllService(query);
      if (res?.status === 200) {
        dispatch(myProductActions.setAll(res));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneProductAction = (id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getOneService(id);
      if (res?.status === 200) {
        dispatch(myProductActions.setSelected(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
