import React from "react";

const Location = () => {
  return (
    <div className="contact-map box-spach">
      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3987.458994465982!2d30.2401111!3d-1.9705000000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwNTgnMTMuOCJTIDMwwrAxNCcyNC40IkU!5e0!3m2!1sen!2srw!4v1697731469136!5m2!1sen!2srw" width="600" height="450"  loading="lazy"></iframe>
    </div>
  );
};

export default Location;
