import React from "react";

const GetInTouch = () => {
  return (
    <section className="contact-us py-16 bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]">
      <div className="container">
        <div className="section-title-white-center v1">
          <h6>Contact Us</h6>
          <h2>Get In Touch</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4 md:mt-0">
            <div className="info-content">
              <ul>
                <li className="flex items-center">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 23">
                      <path d="M9.625 15.1875V9.04297C9.625 8.26953 8.98047 7.66797 8.25 7.66797H6.1875C4.64062 7.66797 3.4375 8.91406 3.4375 10.418V13.8125C3.4375 15.3594 4.64062 16.5625 6.1875 16.5625H8.25C8.98047 16.5625 9.625 15.9609 9.625 15.1875ZM7.5625 14.5H6.1875C5.80078 14.5 5.5 14.1992 5.5 13.8125V10.418C5.5 10.0312 5.80078 9.73047 6.1875 9.73047H7.5625V14.5ZM11 0.75C4.85547 0.75 0.171875 5.86328 0 11.75V12.4375C0 12.8242 0.300781 13.125 0.6875 13.125H1.375C1.71875 13.125 2.0625 12.8242 2.0625 12.4375V11.75C2.0625 6.85156 6.05859 2.8125 11 2.8125C15.8984 2.8125 19.9375 6.85156 19.9375 11.75H19.8945C19.8945 11.8789 19.9375 18.8828 19.9375 18.8828C19.9375 19.9141 19.1211 20.6875 18.0898 20.6875H13.75C13.75 19.5703 12.8047 18.625 11.6875 18.625H10.3125C9.15234 18.625 8.25 19.5703 8.25 20.6875C8.25 21.8477 9.15234 22.75 10.3125 22.75H18.0898C20.2383 22.75 22 21.0312 22 18.8828V11.75C21.7852 5.86328 17.1016 0.75 11 0.75ZM15.8125 16.5625C17.3164 16.5625 18.5625 15.3594 18.5625 13.8125V10.418C18.5625 8.91406 17.3164 7.66797 15.8125 7.66797H13.75C12.9766 7.66797 12.375 8.26953 12.375 9.04297V15.1875C12.375 15.9609 12.9766 16.5625 13.75 16.5625H15.8125ZM14.4375 9.73047H15.8125C16.1562 9.73047 16.5 10.0312 16.5 10.418V13.8125C16.5 14.1992 16.1562 14.5 15.8125 14.5H14.4375V9.73047Z" />
                    </svg>
                  </div>
                  <p>+(250)788974412</p>
                </li>
                <li className="flex items-center">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 24">
                      <path d="M8.25 0.75C3.65234 0.75 0 4.48828 0 9C0 12.0938 0.988281 13.0391 6.48828 21.8047C7.30469 23.0938 9.15234 23.0938 9.96875 21.8047C15.4688 13.0391 16.5 12.0938 16.5 9C16.5 4.44531 12.7617 0.75 8.25 0.75ZM8.25 20.6875C2.75 11.9219 2.0625 11.3633 2.0625 9C2.0625 5.60547 4.8125 2.8125 8.25 2.8125C11.6445 2.8125 14.4375 5.60547 14.4375 9C14.4375 11.3633 13.75 11.8359 8.25 20.6875ZM4.8125 9C4.8125 10.9336 6.31641 12.4375 8.25 12.4375C10.1406 12.4375 11.6875 10.9336 11.6875 9C11.6875 7.10938 10.1406 5.5625 8.25 5.5625C6.31641 5.5625 4.8125 7.10938 4.8125 9Z" />
                    </svg>
                  </div>
                  <p>East Prov-Rwanda Rwamagana, Muyumbu</p>
                </li>
                <li className="flex items-center">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M20 19.75H4A2.76 2.76 0 0 1 1.25 17V7A2.76 2.76 0 0 1 4 4.25h16A2.76 2.76 0 0 1 22.75 7v10A2.76 2.76 0 0 1 20 19.75Zm-16-14A1.25 1.25 0 0 0 2.75 7v10A1.25 1.25 0 0 0 4 18.25h16A1.25 1.25 0 0 0 21.25 17V7A1.25 1.25 0 0 0 20 5.75Z"></path>
                      <path d="M12 13.35a3.25 3.25 0 0 1-1.65-.45L1.62 7.71a.76.76 0 0 1-.27-1 .74.74 0 0 1 1-.26l8.74 5.18a1.69 1.69 0 0 0 1.76 0l8.74-5.18a.74.74 0 0 1 1 .26.76.76 0 0 1-.27 1l-8.67 5.19a3.25 3.25 0 0 1-1.65.45Z"></path>
                    </svg>
                  </div>
                  <p>sales@beegulf.rw</p>
                  {/* <p>rosine@beegulf.rw</p>
                  <p>jadoh@beegulf.rw</p> */}
                </li>
                <li className="flex items-center">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M20 19.75H4A2.76 2.76 0 0 1 1.25 17V7A2.76 2.76 0 0 1 4 4.25h16A2.76 2.76 0 0 1 22.75 7v10A2.76 2.76 0 0 1 20 19.75Zm-16-14A1.25 1.25 0 0 0 2.75 7v10A1.25 1.25 0 0 0 4 18.25h16A1.25 1.25 0 0 0 21.25 17V7A1.25 1.25 0 0 0 20 5.75Z"></path>
                      <path d="M12 13.35a3.25 3.25 0 0 1-1.65-.45L1.62 7.71a.76.76 0 0 1-.27-1 .74.74 0 0 1 1-.26l8.74 5.18a1.69 1.69 0 0 0 1.76 0l8.74-5.18a.74.74 0 0 1 1 .26.76.76 0 0 1-.27 1l-8.67 5.19a3.25 3.25 0 0 1-1.65.45Z"></path>
                    </svg>
                  </div>
                  <p>rosine@beegulf.rw</p>
                  {/* <p>jadoh@beegulf.rw</p> */}
                </li>
                <li className="flex items-center">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M20 19.75H4A2.76 2.76 0 0 1 1.25 17V7A2.76 2.76 0 0 1 4 4.25h16A2.76 2.76 0 0 1 22.75 7v10A2.76 2.76 0 0 1 20 19.75Zm-16-14A1.25 1.25 0 0 0 2.75 7v10A1.25 1.25 0 0 0 4 18.25h16A1.25 1.25 0 0 0 21.25 17V7A1.25 1.25 0 0 0 20 5.75Z"></path>
                      <path d="M12 13.35a3.25 3.25 0 0 1-1.65-.45L1.62 7.71a.76.76 0 0 1-.27-1 .74.74 0 0 1 1-.26l8.74 5.18a1.69 1.69 0 0 0 1.76 0l8.74-5.18a.74.74 0 0 1 1 .26.76.76 0 0 1-.27 1l-8.67 5.19a3.25 3.25 0 0 1-1.65.45Z"></path>
                    </svg>
                  </div>
                  <p>jadoh@beegulf.rw</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="schedule-content">
              <h4>Opening Hours</h4>
              <ul>
                <li>
                  <p>
                    <span className="day">Sat - Mon</span>
                    <span className="time">8 AM - 6 PM</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span className="day">Tus - Sat</span>
                    <span className="time">8 AM - 6 PM</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span className="day">Sunday</span>
                    <span className="time off-day">Off - Day</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
