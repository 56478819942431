import React, { useState } from "react";
import { Divider, Drawer, Space, Button } from "antd";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import productCard from "../../store/productCard";


const ShoppingCard: React.FC<{ onClose: any; showDrawer: any }> = ({
  onClose,
  showDrawer,
}) => {
  const storedCartData = localStorage.getItem("cart");
  const cartItems = storedCartData ? JSON.parse(storedCartData) : [];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const totalQnty = cartItems
    ?.map((data: any) => data?.quantity)
    ?.reduce((total: number, quantity: number) => (total += quantity), 0);

  const calculateItemTotal = (item: any) => {
    const price = parseFloat(item.price);
    const quantity = parseInt(item.quantity, 10);
    return price * quantity;
  };

  // Calculate the overall cart total
  const cartTotal = cartItems.reduce((total: any, item: any) => {
    return total + calculateItemTotal(item);
  }, 0);

  const removeItem = (index: any) => {
    const updatedCart = [...cartItems];
    updatedCart.splice(index, 1);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    onClose();
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Drawer
        title={
          <h5>
            There are{" "}
            <span className="pro-count text-[#fcc41a]">{totalQnty ?? 0}</span>{" "}
            Products
          </h5>
        }
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={showDrawer}
        className="px-0"
        footer={
          <div className="flex flex-col pb-2">
            <div className="total-price-content flex items-center justify-between">
              <h6 className="font-[900] text-[#fcc41a] text-xl mt-2">Total</h6>
              <span className="prices text-[#fcc41a] text-xl mt-2">
                {`RWF ${cartTotal?.toLocaleString()}`}
              </span>
            </div>
            <Divider />
            <Link to={'/productCard'} className="link-anime v1 text-center no-underline">
            Confirm Order
            </Link>
          </div>
        }
        extra={
          <Space>
            <Button onClick={onClose}>Back</Button>
          </Space>
        }
      >
        <div className="w-full">
          <ul>
            {cartItems?.map((el: any, index: any) => (
              <li key={index}>
                <div className="flex items-center justify-between pr-2 py-1">
                  <div className="flex items-center space-x-4">
                    <img
                      src={el?.picture}
                      alt="product"
                      className="h-16 w-10 object-cover"
                    />
                    <div className="">
                      <h6>{el?.name}</h6>
                      <div className="">
                        <span className="">{`${el?.quantity} x `}</span>
                        <span className="">{`RWF ${el?.price}`}</span>
                      </div>
                    </div>
                  </div>
                  <button
                    className="remove-item-btn"
                    onClick={() => removeItem(index)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M15.84 22.25H8.16a3.05 3.05 0 0 1-3-2.86L4.25 5.55a.76.76 0 0 1 .2-.55.77.77 0 0 1 .55-.25h14a.75.75 0 0 1 .75.8l-.87 13.84a3.05 3.05 0 0 1-3.04 2.86zm-10-16 .77 13.05a1.55 1.55 0 0 0 1.55 1.45h7.68a1.56 1.56 0 0 0 1.55-1.45l.81-13z"></path>
                      <path d="M21 6.25H3a.75.75 0 0 1 0-1.5h18a.75.75 0 0 1 0 1.5z"></path>
                      <path d="M15 6.25H9a.76.76 0 0 1-.75-.75V3.7a2 2 0 0 1 1.95-1.95h3.6a2 2 0 0 1 1.95 2V5.5a.76.76 0 0 1-.75.75zm-5.25-1.5h4.5v-1a.45.45 0 0 0-.45-.45h-3.6a.45.45 0 0 0-.45.45zM15 18.25a.76.76 0 0 1-.75-.75v-8a.75.75 0 0 1 1.5 0v8a.76.76 0 0 1-.75.75zM9 18.25a.76.76 0 0 1-.75-.75v-8a.75.75 0 0 1 1.5 0v8a.76.76 0 0 1-.75.75zM12 18.25a.76.76 0 0 1-.75-.75v-8a.75.75 0 0 1 1.5 0v8a.76.76 0 0 1-.75.75z"></path>
                    </svg>
                  </button>
                </div>
                <Divider />
              </li>
            ))}
          </ul>
        </div>
      </Drawer>
    </>
  );
};

export default ShoppingCard;
