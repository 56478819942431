import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsAction } from "../../store/product/actions";
import { getAllProductCategorysAction } from "../../store/productCategory/actions";
import Category from "./Categories";
// import Bestsales from "./Bestsales";
import Products from "./Products";
import { Link } from "react-router-dom";
import Search from "./Search";

interface productType {
  product: string;
  category: string;
  quantity: number;
}

const Shop = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const { product } = useSelector((state: any) => state);
  const [categoryName, setCategoryName] = useState("");
  const [searchKey, setSearchKey] = useState("");
  console.log("searchKey", searchKey);

  useEffect(() => {
    getAllProductCategorysAction(`?`)(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (categoryName) {
      getAllProductsAction(
        `?limit=${limit}&page=${page}&category=${categoryName}`
      )(dispatch);
    } else {
      getAllProductsAction(`?limit=${limit}&page=${page}`)(dispatch);
    }
  }, [dispatch, limit, page, categoryName]);

  const totalPages = Math.ceil(product?.all?.data?.length / limit);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    if (searchKey === "") {
      getAllProductsAction(`?limit=${limit}&page=${page}`)(dispatch);
    }
  }, [searchKey]);

  const handleSearch = async (e: any) => {
    e.preventDefault();
    searchKey &&
      (await getAllProductsAction(
        `?limit=${limit}&page=${page}&sk=${searchKey}`
      )(dispatch));
    console.log(")))))>>>");
  };

  return (
    <Layout>
      <section className="breadcum bg-cover-center bg-[url('assets/img/download-app/app-bg.jpg')]">
        <h2 className="flex items-center justify-center mx-auto pt-24 lg:pt-0" style={{color:'black'}}>
          Shop
        </h2>
      </section>
      <section className="shop box-spach py-16">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="products">
                <div className="pro-display-header lg:flex items-center lg:space-x-10">
                  <div className="pro-left">
                    <p>
                      Showing {totalPages} of {product?.all?.data?.length}{" "}
                      results
                    </p>
                  </div>

                  {/* ======Filter and Sorting========= */}
                  {/* <div className="md:flex items-center justify-between md:space-x-6 space-y-2 md:space-y-0">
                    <select className="page-select border border-gray-500 rounded-md">
                      <option>9 per page</option>
                    </select>
                    <select className="page-select border border-gray-500 rounded-md">
                      <option selected>Default Sorting</option>
                    </select>
                  </div> */}
                </div>
                <Products />
                <div className="pegination-box">
                  <ul>
                    <li>
                      <a
                        href="#x"
                        className="relative"
                        onClick={() => handlePageChange(page - 1)}
                      >
                        <svg
                          viewBox="0 0 512 440"
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute left-4 top-5"
                        >
                          <path d="M481.226 184.062C479.131 183.722 477.011 183.565 474.89 183.593H113.684L121.56 179.93C129.259 176.285 136.263 171.327 142.258 165.276L243.549 63.9851C256.889 51.2504 259.131 30.7644 248.861 15.4459C236.908 -0.877665 213.986 -4.42194 197.661 7.53083C196.342 8.49693 195.089 9.5502 193.911 10.6836L10.7442 193.85C-3.57031 208.149 -3.58291 231.343 10.7155 245.658L10.7442 245.686L193.911 428.853C208.237 443.139 231.431 443.107 245.718 428.781C246.843 427.653 247.892 426.454 248.861 425.19C259.131 409.871 256.889 389.385 243.549 376.651L142.441 275.176C137.066 269.796 130.887 265.284 124.124 261.805L113.134 256.86H472.874C491.587 257.554 508.006 244.482 511.522 226.088C514.76 206.117 501.197 187.302 481.226 184.062Z" />
                        </svg>
                      </a>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li
                        key={index}
                        className={
                          index === page
                            ? "active bg-[#fcc41a] rounded-full"
                            : ""
                        }
                        onClick={() => handlePageChange(index)}
                      >
                        <Link to={""} className="no-underline ">
                          {index + 1}
                        </Link>
                      </li>
                    ))}
                    <li>
                      <a
                        href="#x"
                        className="relative"
                        onClick={() => handlePageChange(page + 1)}
                      >
                        <svg
                          viewBox="0 0 512 440"
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute left-4 top-5"
                        >
                          <path d="M30.7741 255.484C32.8686 255.824 34.9887 255.981 37.1105 255.953L398.316 255.953L390.44 259.617C382.741 263.261 375.737 268.22 369.742 274.27L268.451 375.561C255.111 388.296 252.869 408.782 263.139 424.1C275.092 440.424 298.014 443.968 314.339 432.016C315.658 431.049 316.911 429.996 318.089 428.863L501.256 245.696C515.57 231.398 515.583 208.203 501.284 193.889L501.256 193.86L318.089 10.6934C303.763 -3.5925 280.569 -3.56046 266.282 10.7655C265.157 11.8931 264.108 13.0927 263.139 14.3567C252.869 29.6751 255.111 50.1612 268.451 62.8958L369.559 164.37C374.934 169.751 381.113 174.262 387.876 177.741L398.866 182.687L39.1264 182.687C20.4125 181.992 3.99393 195.064 0.478271 213.459C-2.76035 233.43 10.8032 252.244 30.7741 255.484Z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="widgets">
                <Search
                  onSearch={setSearchKey}
                  searchKey={searchKey}
                  handleSearch={handleSearch}
                />
                <Category
                  setCategoryName={setCategoryName}
                  categoryName={categoryName}
                />
                {/* =======This will work when they start to operate======= */}
                {/* <Bestsales /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Shop;
