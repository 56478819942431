import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./product";
import productCategoryReducer from "./productCategory";
import feedbackReducer from "./feedback";
import blogPostReducer from "./blogs";
import appointmentReducer from "./appointment";
import testimonialReducer from "./testimonial";
import ApitourismReducer from "./apitourism";
import ApitourismCategoryReducer from "./apitourismCategory";
import OrderCardReducer from "./ordercard";



const store = configureStore({
  reducer: {
    product: productReducer,
    productCategory: productCategoryReducer,
    feedback: feedbackReducer,
    blogPost: blogPostReducer,
    appointment: appointmentReducer,
    testimonial: testimonialReducer,
    apitourism: ApitourismReducer,
    apitourismcategory: ApitourismCategoryReducer,
    ordercard: OrderCardReducer,
  },
});

export default store;
