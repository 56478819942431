import Layout from "../../layout/Layout";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActivePrice } from "../../../utils/converter";
import { useEffect, useState } from "react";
import { getOneProductAction } from "../../../store/product/actions";

//import ProductDescription from "./ProductDescription";

const Shop = () => {
  const { product } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { productId } = useParams();
  useEffect(() => {
    productId && getOneProductAction(productId)(dispatch);
    console.log("PRODUCT::", product);
    const decriptionPanel = document.getElementById("description-tab-pane");
    if(product != null && decriptionPanel != null){
      decriptionPanel.innerHTML = product?.selected?.productDescription
    }
  }, [productId, dispatch]);

  const [quantity, setQuantity] = useState(1);
  const storedCart = localStorage.getItem("cart");
  const [cart, setCart] = useState(storedCart ? JSON.parse(storedCart) : []);

  const handleAddToCart = () => {
    if (!product) {
      alert("Please select a product first.");
      return;
    }

    const productName = product?.selected?.product;
    const productPrice = getActivePrice(product?.selected?.prices) ?? "0";
    const productCategory = product?.selected?.category?.name;
    const productImage = product?.selected?.images[0];
    const productdescription= product?.selected?.description;

    const existingProductIndex = cart.findIndex(
      (item: any) => item.name === productName
    );

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity = quantity; // Update quantity
      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } else {
      const productInfo = {
        picture: productImage,
        name: productName,
        price: productPrice,
        category: productCategory,
        quantity: quantity,
      };

      const updatedCart = [...cart, productInfo];
      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <Layout>
      <section className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]">
        <div className="container">
          <h2>Shop Details</h2>
        </div>
      </section>
      <section className="shop-details box-spach py-16">
        <div className="container">
          <div className="product-display row">
            <div className="col-lg-6">
              <div className="display-left">
                <div className="big-box-img">
                  <Link
                    to={""}
                    className="zoom-btn venobox"
                    data-gall="shops-display-demos"
                  >
                    <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.8032 18.4474L14.6837 13.3279C15.8808 11.7851 16.445 9.84412 16.2614 7.90001C16.0777 5.9559 15.16 4.15486 13.6952 2.86357C12.2303 1.57227 10.3283 0.887805 8.37654 0.949516C6.42475 1.01123 4.56984 1.81448 3.18947 3.19573C1.80909 4.57699 1.00702 6.4324 0.946556 8.38423C0.886087 10.3361 1.57176 12.2376 2.86399 13.7016C4.15622 15.1657 5.95784 16.0822 7.90207 16.2646C9.8463 16.447 11.787 15.8816 13.329 14.6835L18.4481 19.8025C18.6288 19.9771 18.8709 20.0737 19.1222 20.0715C19.3735 20.0693 19.6138 19.9685 19.7915 19.7908C19.9692 19.6131 20.07 19.3728 20.0722 19.1215C20.0743 18.8702 19.9777 18.6282 19.8032 18.4474ZM4.56625 12.6974C3.6255 11.7568 3.03999 10.5191 2.9095 9.19525C2.779 7.87136 3.11159 6.54317 3.8506 5.43701C4.5896 4.33085 5.6893 3.51515 6.9623 3.12891C8.2353 2.74266 9.60285 2.80977 10.8319 3.31879C12.061 3.82781 13.0755 4.74726 13.7027 5.92046C14.3299 7.09366 14.5308 8.44802 14.2713 9.75278C14.0118 11.0575 13.308 12.232 12.2797 13.0759C11.2513 13.9199 9.96219 14.3812 8.63188 14.3812C7.10718 14.3804 5.64506 13.7749 4.56625 12.6974Z"></path>
                    </svg>
                  </Link>
                  <div className="slider">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <img
                          src={
                            product?.selected?.images[0] ??
                            "https://cdn.xxl.thumbs.canstockphoto.com/jar-of-honey-with-honeycomb-on-wooden-table-pictures_csp18394789.jpg"
                          }
                          alt="big-img"
                          className="w-[600px] h-[400px] object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="display-right">
                <h3>{product?.selected?.product}</h3>
                <h4>{`RWF ${
                  getActivePrice(product?.selected?.prices)?.toLocaleString() ??
                  0
                }`}</h4>
                <p>{product?.selected?.category?.name}</p>
                {(product?.selected?.status === 'Inactive') ?
                  (
            
                 <h4 style={{color:'red'}}>SOLD OUT</h4>

                  ) : (
                   <> 
                <div className="quan-card flex items-center">
                  <div className="quantity-count mt-4">
                    <button
                      className="count-down-btn"
                      onClick={handleDecreaseQuantity}
                    >
                      -
                    </button>
                    <input
                      className="count-number"
                      type="number"
                      value={quantity}
                      readOnly
                    />
                    <button
                      className="count-plus-btn"
                      onClick={handleIncreaseQuantity}
                    >
                      +
                    </button>
                  </div>
                  <Link
                    to={""}
                    className="link-anime v2 no-underline"
                    onClick={handleAddToCart}
                  >
                    add to cart
                  </Link>
                </div>
                    
                <ul>
                  <li>
                    <p>
                      <span className="title">Category :</span>
                      <span className="text">
                        {product?.selected?.category?.name}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="title">Available :</span>
                      <span className="text">{`${
                        product?.selected?.quantity?.toLocaleString() ?? 0
                      }Kg`}</span>
                    </p>
                  </li>
                </ul>
                     </>
                    )
                }
              </div>
            </div>
          </div>
          <div className="product-tab">
            <ul className="nav-tabs" id="myTab" role="tablist">
              <li>
                <button
                  className="active"
                  id="description-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#description-tab-pane"
                  type="button"
                >
                  Description
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="description-tab-pane">
                <p>
                  {product?.selected?.productDescription}
                </p>
              </div>
            </div>
          </div>
          <div className="related-products">
            <h3>Related products</h3>
            <div className="row">
              {product?.all?.data
                ?.slice(0, 3)
                ?.filter((d: any) => d?.product !== product?.selected?.product)
                ?.map((data: any) => (
                  <div className="col-md-6 col-xl-3">
                    <div className="product-card">
                      <div className="product-img">
                        <img src={data?.images[0]} alt="items" />
                      </div>
                      <div className="product-content">
                        <h4>{data?.product}</h4>
                        <h5>
                          {`RWF ${getActivePrice(
                            data?.prices
                          )?.toLocaleString()}`}
                        </h5>
                        <p>
                          Weight: <strong></strong>
                        </p>
                      </div>
                      <div className="shop-btn">
                        <Link to={""} className="relative">
                          <svg
                            viewBox="0 0 17 17"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute left-4 top-4"
                          >
                            <path d="M15.0596 4.35946C14.976 3.9409 14.6416 3.60605 14.2236 3.60605H11.7154C11.7154 1.76439 10.2105 0.257568 8.37121 0.257568C6.5319 0.257568 5.02701 1.76439 5.02701 3.60605H2.51886C2.10083 3.60605 1.76641 3.9409 1.68281 4.35946L0.0107061 16.0792C-0.072899 16.5814 0.345126 17 0.846757 17H15.8957C16.3973 17 16.8153 16.5814 16.7317 16.0792L15.0596 4.35946ZM8.37121 1.93181C9.29087 1.93181 10.0433 2.68522 10.0433 3.60605H6.69911C6.69911 2.68522 7.45156 1.93181 8.37121 1.93181ZM1.85002 15.3257L3.2713 5.2803H5.02701V6.11742C5.02701 7.20567 6.69911 7.20567 6.69911 6.11742V5.2803H10.0433V6.11742C10.0433 7.20567 11.7154 7.20567 11.7154 6.11742V5.2803H13.4711L14.8924 15.3257H1.85002Z"></path>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Shop;
