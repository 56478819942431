import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActivePrice } from "../../utils/converter";

const Products = () => {
  const { product } = useSelector((state: any) => state);
  const navigate = useNavigate();

  return (
    <div className="row">
      {product?.all?.data?.map((d: any, index: number) => (
        <div className="col-sm-6 col-xl-4" key={index}>
          <div
            className="product-card"
            onClick={() => navigate(`/shop/${d?.product}/${d?._id}`)}
          >
            {d?.images ? (
              <div className="product-img">
                <img src={d?.images[0]} alt="items" />
              </div>
            ) : (
              <div className="product-img">
                <img
                  src={
                    "https://stoneycreekfarmtennessee.com/wp-content/uploads/2023/04/bee-3.jpg"
                  }
                  alt="items"
                />
              </div>
            )}
            <div className="product-content">
              {(d?.status === 'Inactive') && (
            
                 <h5 style={{color:'red'}}>SOLD OUT</h5>

              )}
              <h4>{d?.product}</h4>
              <h5>{`RWF ${getActivePrice(d?.prices)?.toLocaleString()}`}</h5>
              
            </div>
            {/* <div className="shop-btn">
              <Link to={""} className="relative">
                <svg
                  viewBox="0 0 17 17"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-4 top-4"
                >
                  <path d="M15.0596 4.35946C14.976 3.9409 14.6416 3.60605 14.2236 3.60605H11.7154C11.7154 1.76439 10.2105 0.257568 8.37121 0.257568C6.5319 0.257568 5.02701 1.76439 5.02701 3.60605H2.51886C2.10083 3.60605 1.76641 3.9409 1.68281 4.35946L0.0107061 16.0792C-0.072899 16.5814 0.345126 17 0.846757 17H15.8957C16.3973 17 16.8153 16.5814 16.7317 16.0792L15.0596 4.35946ZM8.37121 1.93181C9.29087 1.93181 10.0433 2.68522 10.0433 3.60605H6.69911C6.69911 2.68522 7.45156 1.93181 8.37121 1.93181ZM1.85002 15.3257L3.2713 5.2803H5.02701V6.11742C5.02701 7.20567 6.69911 7.20567 6.69911 6.11742V5.2803H10.0433V6.11742C10.0433 7.20567 11.7154 7.20567 11.7154 6.11742V5.2803H13.4711L14.8924 15.3257H1.85002Z"></path>
                </svg>
              </Link>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;
