import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllBlogsService = async (query: string) => {
  return HttpRequest.get(`${SERVER_URL}/blogs${query}`);
};

export const getOneBlogsService = async (id: string) => {
  return HttpRequest.get(`${SERVER_URL}/blogs/one/${id}`);
};
