import React, { useEffect } from "react";
import { getAllmyTestimonialAction } from "../../store/testimonial/actions";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "better-react-carousel";

const Testimonial = () => {
  const { testimonial } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllmyTestimonialAction("?isPublished=true")(dispatch);
  }, [dispatch]);

  return (
    <div className="testimonial v1 bg-cover-center bg-[url('assets/img/testimonial/bg.jpg')] pt-16">
      <div className="container">
        <div className="section-title-white-center v1">
          <h6 className="text-gray-900">Testimonial</h6>
          <h2>What People Say About products</h2>
        </div>
        <Carousel cols={3} rows={1} loop={true} autoplay={3000}>
          {testimonial?.all?.data?.map((el: any) => (
            <Carousel.Item>
              <div className="xswiper-slide testimonial-card">
                <div className="profile">
                  <div className="profile-img">
                    <img src={el?.picture} alt="profile" />
                  </div>
                  <div className="profile-content">
                    <h6>
                      <a href="#name">{el?.names}</a>
                    </h6>
                    <p>{el?.createdAt.slice(0, 11)}</p>
                  </div>
                </div>
                <p>{el?.description}</p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial;
