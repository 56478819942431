import NewLetter from "./NewLetter";
import FooterBody from "./FooterBody";

const Footer = () => {
  return (
    <div id="xmain-wrapper ">
      <div className="info-footer v1 bg-cover-center lg:mt-48 bg-[url('assets/img/footer/bg-1.jpg')]">
        <div className="container">
          <NewLetter />
          <FooterBody />
          <div className="row main-footer">
            <div className="col-lg-6-center">
              <p>Copyright © 2023 by BeeGulf, All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
