const statusColor = [
  ["bee benefit", "#795548"],
  ["bee nutrition", "#ffa500"],
  ["1", "#fed607"],
  ["2", "#ECBC4C"],
  ["3", "#0fa958"],
  ["4", "#2196F3"],
  ["5", "#00BCD4"],
  ["6", "#4CAF50"],
  ["7", "#F19F1D"],
];

export const searchValue = (value: string) => {
  for (let i = 0; i < statusColor.length; i++) {
    if (statusColor[i][0]?.toLowerCase() === value?.toLowerCase()) {
      return statusColor[i][1];
      //   break;
    }
  }
};
