export const menuItems = [
  {
    label: "Farming",
    link: "/",
    // subItems: [
    //   { label: "Construction", link: "" },
    //   { label: "BeeHive Manifacturing", link: "" },
    //   { label: "BeeColony Multiplication", link: "" },
    //   { label: "BeeHive Transportation", link: "" },
    //   { label: "Swarm Removal", link: "" },
    // ],
  },
  {
    label: "Our Service",
    link: "",
    subItems: [
      { label: "Training", link: "/training" },
      { label: "Consultancy", link: "/consultancy" },
      { label: "API-Tourism", link: "/apitourism" },
      // {
      //   label: "API-Tourism",
      //   link: "",
      //   subItemsSub: [
      //     { label: "BEE FARM", link: "/bee/farm" },
      //     { label: "BEE HOME", link: "/bee/home" },
      //   ],
      // },
    ],
  },
  {
    label: "Honey Benefits",
    link: "",
    subItems: [
      { label: "Beauty benefits", link: "/honey/benefits" },
      { label: "Nutritional benefits", link: "/honey/nutrition" },
    ],
  },
  {
    label: "Blogs",
    link: "/blogs",
  },
  {
    label: "Shop",
    link: "/shop",
  },
  {
    label: "About us",
    link: "/about",
  },
  {
    label: "Contact",
    link: "/contact",
  },
  
];

export const faqLeft = [
  {
    id: 1,
    title: "Why does your honey looks expensive? ",
    content:
      " Genuine honey is expensive for several reasons: The Overall, the price of our honey reflects the efforts and investments we made to produce the high-quality and pure while maintaining the health of their bee colonies and adhering to ethical and sustainable practices. Consumers who prioritize these qualities and are willing to pay more for them contribute to the higher cost of genuine honey.",
  },
  {
    id: 2,
    title: "Ni Ubunyarwanda? (Is this natural, Rwandan Honey?) ",
    content:
      "This is the question that pops into the head of everyone who want to know the truest definition of pure honey.  We are local based in Rwanda where we manage our apiaries and we do not feed bees with other foods than then to travel mills for foraging in plants and flowers which male this honey pure/natural and medicinal. ",
  },
  {
    id: 3,
    title: "Can honey be used as a natural remedy?",
    content:
      "Yes, honey has been used for its medicinal properties for centuries. It is applied topically to heal wounds, treat coughs, allergies, and skin care. Additionally, it is used as food supplements like on salads, bread, cakes, cooking and others.",
  },
];

export const faqRight = [
  {
    id: 4,
    title: " Is Crème Royale good for all ages? ",
    content:
      "It is mainly made from Beeswax, a popular natural product used for skincare, cosmetics, and topical applications. While beeswax itself has several properties that can benefit the skin, researchers demonstrated its potential therapeutic and cosmetic uses to young and adults as allergy free.If you have specific skin concerns or conditions, consult with a dermatologist or skincare professional before using any new product, including beeswax-based creams.",
      
  },
  {
    id: 5,
    title: "How much money should I invest into beekeeping business?",
    content:
      "If you are interested in investing into beekeeping business, you can reach out to us. We offer consultancy and training specifically in beekeeping projects management whether you are a beginner or on you need skills and modernizing your beekeeping business.",
  },
  {
    id: 6,
    title: "How much honey can a langstroth bee hive produce? ",
    content:
      "The produce can vary widely depending on several factors, including the strength and health of the bee colony, the availability of nectar and pollen sources in the area, local climate conditions and beekeeper management practices. It's important to note that honey production can fluctuate from year to year, and not all colonies will perform equally. Some beekeepers may achieve higher or less honey yields with certain colonies. Regular monitoring and management of the hive are essential to maximize honey production and ensure the health and well-being of the bee colony.",
  },
];
export  const embeddedI =[
{
  embedId: "myidstring",
  children: null
}
];