import React from "react";

const DownLoadApp = () => {
  return (
    <section className="download-app ">
      <div className="container">
        <div
          className="mobile-ads-box bg-cover-center bg-[url('assets/img/download-app/app-bg.jpg')]"
          data-background="assets/img/download-app/app-bg.jpg"
        >
          <div className="row">
            <div className="col-md-5">
              <div className="side-img"></div>
            </div>
            <div className="col-md-7 order-lg-first">
              <div className="section-title shap-green v1">
                <h6>BeeGulf</h6>
                <h2>Buy and Enjoy it. And be Health For Life</h2>
                <div className="download-btns">
                  <ul>
                    <li>
                      {/* <!-- <a href="#"><img src="assets/img/download-app/apple-btn.svg" alt="app-store"></a> --> */}
                    </li>
                    <li>
                      {/* <!-- <a href="#"><img src="assets/img/download-app/google-btn.svg" alt="play-store"></a> --> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownLoadApp;
