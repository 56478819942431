import React from "react";
import Layout from "../layout/Layout";
import Img from "../../assets/img/services-details/big-img.jpg";
import Img1 from "../../assets/img/services-details/small-img-1.jpg";
import Img2 from "../../assets/img/services-details/mid-img-1.jpg";
import Img3 from "../../assets/img/our-sweet-clients/logo-1.png";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <Layout>
      <section
        className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]"
        data-background="assets/img/breadcum/bg.jpg"
      >
        <div className="container">
          <h2>Services Details</h2>
        </div>
      </section>
      <section className="services-details py-16">
        <div className="container">
          <div className="big-post-img">
            <img src={Img} alt="post-img" />
          </div>
          <h3>Beekeeping classNamees</h3>
          <p>
            Honey bees stockpile honey in the hive. Within the hive is a
            structure made from wax called honeycomb. The honeycomb is made up
            of hundreds or thousands of hexagonal cells, into which the bees
            regurgitate honey for storage. Other honey-producing species of bee
            store the substance in different structures, such as the pots made
            of wax and resin used by the stingless bee Honey is sweet because of
            its high concentrations of the monosaccharides fructose and
            glucose.Honey is sweet because of its high concentrations of the
            monosaccharides fructose and glucose. It has about the same relative
            sweetness as sucrose (table sugar). One standard tablespoon (15 mL)
            of honey provides around 190 kilojoules (46 kilocalories) of food
            energy.It has attractive chemical properties for baking and a
            distinctive flavor when used as a sweetener.Most microorganisms
            cannot grow in honey and sealed honey therefore does not spoil.
            Samples of honey discovered in archaeological contexts have proven
            edible even after thousands of years.
          </p>
          <div
            className="feedbac-box bg-cover-center bg-[url('assets/img/services-details/bg.jpg')]"
            data-background="assets/img/services-details/bg.jpg"
          >
            <div className="box-img">
              <img src={Img1} alt="small-img" />
            </div>
            <div className="box-img order-xl-2">
              <img src={Img1} alt="small-img" />
            </div>
            <div className="box-content">
              <h5>Excellent</h5>
              <ul className="start start-5">
                <li>
                  <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"></path>
                  </svg>
                </li>
                <li>
                  <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"></path>
                  </svg>
                </li>
                <li>
                  <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"></path>
                  </svg>
                </li>
                <li>
                  <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"></path>
                  </svg>
                </li>
                <li>
                  <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"></path>
                  </svg>
                </li>
              </ul>
              <p>Trust Score 4.6 (Based on 1,500 Reviews)</p>
              <Link
                to={""}
                className="feedbac-btn justify-center space-x-2 flex items-center"
              >
                <span className="icon mt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                    <path d="M16.657 13.084a1 1 0 0 0-1.316.516 3.647 3.647 0 0 1-6.682 0 1 1 0 0 0-1.832.8 5.645 5.645 0 0 0 10.346 0 1 1 0 0 0-.516-1.316zM8.499 11a1.501 1.501 0 1 0 0-3.002 1.501 1.501 0 0 0 0 3.002zM15.499 11a1.501 1.501 0 1 0 0-3.002 1.501 1.501 0 0 0 0 3.002z"></path>
                  </svg>
                </span>
                <span className="text">Feedback</span>
              </Link>
            </div>
          </div>
          <h3>What We Do</h3>
          <p>
            Bees are among the few insects that can create large amounts of body
            heat. They use this ability to produce a constant ambient
            temperature in their hives. Hive temperatures are usually around 35
            °C (95 °F) in the honey-storage areas. This temperature is regulated
            either by generating heat with their bodies or removing it through
            water evaporation. The evaporation removes water from the stored
            honey, drawing heat from the colony. The bees use their wings to
            govern hive cooling. Coordinated wing beating moves air across the
            wet honey, drawing out water and heat. Ventilation of the hive
            eventually expels both excess water and heat into the outside world.
          </p>
          <div className="mid-box-imgs">
            <div className="box-img">
              <img src={Img2} alt="mid-img" />
            </div>
            <div className="box-img">
              <img src={Img2} alt="mid-img" />
            </div>
          </div>
        </div>
      </section>
      <section className="our-sweet-clients v1 box-spach">
        <div className="container">
          <div className="section-title-center v1">
            <h6>Our Sweet Clients and Partners</h6>
          </div>
          <div className="slider">
            <ul className="swiper-wrapper flex justify-between items-center">
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
              <li className="swiper-slide">
                <Link to={""}>
                  <img src={Img3} alt="logo" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Service;
