import Header from "../navbar/Navbar";
import Footer from "../footer/Footer";

const Layout = (props: any) => {
  return (
    <div className="landScreenBack">
      <div className={`gap-8 w-full h-screen overflow-y-auto`}>
        <div className="flex min-h-screen">
          <div className="w-full min-h-screen">
            <Header />
            <div>{props.children}</div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
