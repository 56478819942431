import React, { useState } from "react";
import { faqLeft } from "../../assets/data/dataItems";
import { faqRight } from "../../assets/data/dataItems";

interface faqType {
  id: number;
  title: string;
  content: string;
}

const FAQ = () => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const handleButtonClick = (id: number) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="faq box-spach py-16">
      <div className="container">
        <div className="section-title-center v1">
          <h6>Faq</h6>
          <h2>Do You Have Any Questions? Feel Free to Ask</h2>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="right-countent">
              <ul className="accordion" id="accordionFAQ">
                {faqLeft?.map((d: faqType) => (
                  <li key={d?.id}>
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq1"
                      onClick={() => handleButtonClick(d.id)}
                    >
                      <span className="text">{d?.title}</span>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 28444 28444"
                        >
                          <path d="M26032 3553 14222 15363 2412 3553l1768-1768 10042 10042L24264 1785zm0 11296L14222 26659 2412 14849l1768-1768 10042 10042 10042-10042z"></path>
                        </svg>
                      </span>
                    </button>
                    <div
                      id="faq1"
                      className="xcollapse"
                      data-bs-parent="#accordionFAQ"
                    >
                      {activeId === d.id && (
                        <div className="box-content">
                          <p>{d?.content}</p>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right-countent">
              <ul className="accordion" id="accordionFAQ">
                {faqRight?.map((d: faqType) => (
                  <li key={d?.id}>
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq1"
                      onClick={() => handleButtonClick(d.id)}
                    >
                      <span className="text">{d?.title}</span>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 28444 28444"
                        >
                          <path d="M26032 3553 14222 15363 2412 3553l1768-1768 10042 10042L24264 1785zm0 11296L14222 26659 2412 14849l1768-1768 10042 10042 10042-10042z"></path>
                        </svg>
                      </span>
                    </button>
                    <div
                      id="faq1"
                      className="xcollapse"
                      data-bs-parent="#accordionFAQ"
                    >
                      {activeId === d.id && (
                        <div className="box-content">
                          <p>{d?.content}</p>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
