import { notification } from "antd";
import { myOrderCardActions } from ".";
import { sendOrderCardService } from "./services";

export const sendOrderCardAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myOrderCardActions.setIsFetching(true));
      const res = await sendOrderCardService(data);
      if (res?.status === 201) {
        dispatch(myOrderCardActions.setNew(res));
        notification.success({ message: "Thank your, it will take short time" });
        dispatch(myOrderCardActions.setIsFetching(false));
      }
      dispatch(myOrderCardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
