import React from "react";
import Layout from "../../components/layout/Layout";
import Img1 from "../../assets/img/gallery-single/big-box-img-1.jpg";
import Img2 from "../../assets/img/gallery-single/user-1.png";
import Img3 from "../../assets/img/gallery-single/box-img-1.jpg";
import Img4 from "../../assets/img/gallery-single/box-video-img.jpg";
import { nutribenefitData } from "../../assets/data/dummyData";
interface nutribenefitType {
  key: number;
  content: string;
}

const Nutribenefit = () => {
  return (
    <Layout>
      <section className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]">
        <h2 className="flex items-center justify-center mx-auto pt-24 lg:pt-0">
          Nutritional benefits
        </h2>
      </section>
      <section className="gallery-single box-spach py-16">
        <div className="container">
          <div className="big-img-count">
            <div className="box-img">
              <img src={Img1} alt="gallery" />
            </div>
          </div>
          

          <h3>HEALTH BENEFITS OF HONEY</h3>

          <h4>DO YOU HAVE ANY FUN PLANS FOR THE SUMMER?</h4>
          <p>Here’s the recipe card for this delicious homemade lemonade with honey.
             I hope you can beat the heat and enjoy the sunshine with a tall glass of this lemonade in hand.
            Take the first step towards a healthier life today! Learn how you can turn the simplest foods into healthier snacks with BeeGulf Honey.
             With BeeGulf Honey recipes you can learn how to incorporate the various benefits of honey into your daily diet.
             Grab a bottle of BeeGulf Honey today!</p>
             <p>Honey for Fitness article (for other article for all our products, you can use this in their place, we shall create relevant content for them)</p>
           
          <div
            className="user bg-cover-center bg-[url('assets/img/gallery-single/bg.jpg')]"
            data-background=""
          >
            <div className="user-profile">
              <div className="icon">
                <svg viewBox="0 0 736 657" xmlns="http://www.w3.org/2000/svg">
                  <path d="M64.2675 1.66667C47.4675 4.2 29.0675 17.2667 19.6009 33.5333C10.5342 49 10.6675 45.9333 10.6675 171.133C10.6675 293.933 10.6675 293.133 18.4009 307.533C22.9342 316.067 36.1342 329.267 44.9342 333.933C57.2009 340.6 65.0675 341.667 105.868 341.667H142.934L142.134 348.867C140.668 360.6 135.201 380.733 130.134 393.133C120.934 415.533 109.468 431.8 92.1342 446.467C78.6675 458.067 69.4675 463.933 48.6675 474.6C26.8009 485.667 18.4009 491.8 12.0009 501.933C3.86753 514.333 0.667529 525.267 0.800862 540.333C0.800862 551.4 1.3342 554.2 4.9342 563C12.6675 581.4 31.8675 620.867 37.2009 629.133C44.0009 639.4 54.2675 647.4 67.6009 652.6C76.4009 656.067 79.8675 656.733 90.0009 656.733C103.734 656.867 109.601 655.267 130.268 645.667C172.668 625.8 210.668 599.8 243.068 568.333C272.934 539.533 292.934 512.6 309.868 478.333C329.201 439.4 338.401 405.8 345.201 351C350.934 303.8 351.334 292.467 351.334 171C351.334 39.9333 351.868 47 341.334 31.4C334.534 21.2667 330.534 17.1333 320.934 10.8667C305.201 0.599998 312.801 1.13333 185.334 0.866665C122.668 0.733332 68.1342 1.13333 64.2675 1.66667ZM289.068 163C289.468 263.133 288.534 299.533 284.534 334.333C280.401 371 274.001 401.933 266.001 424.333C242.268 490.333 187.201 547.8 112.001 585C90.4009 595.8 90.0009 595.933 87.4675 592.067C82.5342 584.333 62.5342 539.933 63.2009 538.2C63.6009 537.133 67.7342 534.467 72.2675 532.333C83.6009 527.267 101.734 516.733 115.734 507C151.201 482.733 178.268 446.467 192.668 404.067C202.268 375.533 206.268 349.533 207.601 307.667L208.401 279.533L142.134 279.667C105.601 279.667 75.0675 279.267 74.2675 278.733C73.2009 278.067 72.8009 247.933 72.6675 172.067C72.5342 113.933 72.6675 65.5333 73.0675 64.6C73.4675 63.2667 95.8675 63 181.201 63.2667L288.668 63.6667L289.068 163Z" />
                  <path d="M448.001 1.79997C438.134 3.39997 423.334 10.8666 416.134 17.9333C408.667 25.2666 400.801 37.2666 397.867 46.0666C395.601 52.4666 395.467 62.4666 395.067 165.667C394.667 244.867 394.934 281 396.001 287.267C400.267 312.6 419.334 333 444.934 339.667C450.801 341.267 461.201 341.667 490.134 341.667H527.601L526.534 348.6C523.067 370.333 516.401 391 507.201 408.333C492.134 436.467 470.401 455.933 432.001 475C412.534 484.733 405.334 489.933 398.267 499.267C386.001 515.667 381.601 536.067 386.401 553.667C388.934 562.867 413.467 616.733 419.467 626.467C431.467 645.933 452.001 657 475.334 657C487.601 657 493.867 655.133 515.201 645.267C590.001 610.867 650.667 556.867 687.201 492.333C707.601 456.467 720.934 413.8 728.001 362.333C734.534 314.867 734.934 304.733 735.601 179C736.134 70.7333 736.001 58.8666 734.001 50.6C727.867 25.4 705.867 5.26663 680.667 1.66663C668.934 0.0666324 458.267 0.199966 448.001 1.79997ZM673.734 78.6C674.934 103.133 673.601 270.333 672.001 296.333C666.667 383.267 652.934 433.4 621.467 480.2C610.001 497.133 601.734 507 585.201 523.267C559.467 548.467 534.401 566.067 497.867 584.6C481.201 593 475.467 595.267 473.867 594.333C472.001 593.133 446.667 541.533 446.667 538.867C446.667 538.2 451.601 535.267 457.734 532.2C489.201 516.333 512.267 500.067 531.201 480.2C572.801 436.467 591.867 379.667 592.001 299.4C592.001 282.6 591.734 280.467 589.734 279.8C588.401 279.533 567.201 279.4 542.667 279.667C518.134 279.933 488.801 279.8 477.734 279.4L457.334 278.6V171.667C457.334 112.867 457.734 64.3333 458.267 63.9333C458.667 63.4 507.201 63 566.001 63H672.934L673.734 78.6Z" />
                </svg>
              </div>
              <img src={Img2} alt="user" />
            </div>
            <div className="user-content">
              <h4>Stay Fit, Feel Young!</h4>
              <h4>
                "Give a healthy start to your day with the spoonful of BeeGulf Honey with a glass of warm water."
              </h4>
              <ul>
                <li>
                  <a href="#">
                    <svg viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.71875 9L9.15625 6.125H6.375V4.25C6.375 3.4375 6.75 2.6875 8 2.6875H9.28125V0.21875C9.28125 0.21875 8.125 0 7.03125 0C4.75 0 3.25 1.40625 3.25 3.90625V6.125H0.6875V9H3.25V16H6.375V9H8.71875Z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 511 511.9"
                    >
                      <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                      <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zM423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 0 0 2.882 0z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <h3>Health Benefits Of Honey For Kids</h3>
          <p>
          Honey is nature’s sweetest gift to mankind. There is something magnificently magnetic about this sweet liquid form of gold that has attracted the attention of many since time immemorial. As a super food, it not only taste wonderful but also packs a punch when it comes to providing multitude health benefits to people across ages especially children. 
          </p>
          <p>Putting its seductive color and flavor aside, Honey forms the base of most of the Ayurvedic medicines and is widely used in home remedies to treat diseases and illnesses like cough & cold, flu, eczema, and wounds etc.
            Rich in antioxidants, flavonoids, minerals, vitamins and other essential nutrients and antiseptics and antibacterial 
              properties; Honey is one power food you can safely rely on when everything else fails.  
            </p>
            <p>While the benefits of honey are many, did you know administering honey to kids in times of emergencies or illnesses like cold and cough can provide them with quick relief? It is any day better than rushing to a pediatrician every time and exposing your child to medicines, which can prove dangerous for their developing digestive system and other vital organs in future.</p>

          <div className="box-gallery">
            <div className="box-img">
              <img src={Img3} alt="gallery" />
            </div>
            <div className="box-img">
              <img src={Img3} alt="gallery" />
            </div>
          </div>
           <h3>
                  What are the health benefits of honey for kids?
            </h3>
            <div className="check-and-heading">
            <ul>
              {nutribenefitData?.map((d: nutribenefitType) => (
                <li className="flex items-center" key={d?.key}>
                  <div className="icon shadow-inner border border-gray-900 relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      className="top-3 left-2 absolute"
                    >
                      <path d="M18.6 2.3 6 14.9l-4.6-4.6L0 11.7l6 6 14-14z"></path>
                    </svg>
                  </div>
                  <h6>{d?.content}</h6>
                </li>
              ))}
            </ul>
          </div>
          <p>Have a look at the benefits of honey for kids in detail and the ways in which you can use honey to make natural home remedies:</p>
          <h4>To get relief from cough & cold:</h4>
          <p>Children are most vulnerable to illnesses and problems that come with season change. Cough & cold being most common among them. Benefits of honey for children include providing them relief from symptoms of cold and cough like runny nose, mild fever, irritated or sore throat and sneezing etc. Honey has antiviral, antibacterial and antioxidant properties that helps fight the viral, bacterial or fungal infection that cause these problems and treat the underlying symptoms from the root. Prepare this effective honey based home remedy to treat the symptoms of cold & cough in your child:</p>
           <h5>Method:</h5>
          <p>	Mix 1 tbsp of lukewarm Honey with ¼ spoon of cinnamon powder</p>
          <p>	Give it to your child twice a day daily or till the time the symptoms have vanished</p>
          <p> Preferably, give it during bedtime as it will aid in giving your child a good night’s sleep</p>
          
          <p>	Using the combination of Ginger and Honey is also one of the well-known home remedies for treating cough and cold. If you want to get this remedy without any hassles, just use BeeGulf’s Ginger and Honey for cough order your on +250 788 974 412</p>
          
          <h5>For promotion of healthy digestive system:</h5>

          <p>Among the many known benefits of Honey for kids, using it for improved digestive system is the most commonly practised one.  Consumption of honey benefits the digestive tract by keeping it clear of any blockages and regularise the bowel movement. It also aids in providing relief during stomach infections like indigestion, constipation, bloating and flatulence etc. If your child often complains of stomach disorders, prepare this below given recipe and feed it to him/her on a regular basis to soothe the stomach and keep the digestive system healthy & happy.</p>
          
          <div className="box-video box-img">
            <button
              className="play-video-btn v1 venobox relative"
              data-vbtype="video"
              data-maxwidth="800px"
              data-autoplay="true"
              data-href="https://youtu.be/sYNgtwsfhx4?start=65"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                className="absolute top-6 left-6"
              >
                <path d="M26.17 12.37 9 2.45A3.23 3.23 0 0 0 7.38 2 3.38 3.38 0 0 0 4 5.38v21.29a3.33 3.33 0 0 0 5.1 2.82l17.19-10.86a3.65 3.65 0 0 0-.12-6.26z"></path>
              </svg>
            </button>
            <img src={Img4} alt="gallery" />
          </div>
          <h3>How does Honey Help You Stay Healthy?</h3>
          <p>Honey is one of the most appreciated and valued natural products introduced to humankind since ancient times. Honey is used not only as a nutritional product but also as an alternative treatment for clinical conditions ranging from wound healing to other treatments. The honey has been reported to exert antioxidant, antimicrobial, anti-inflammatory & ant proliferative.</p>
          <p>It is to be noted that it is not sugar but the high level of carbohydrates consumed through sugar or other food items that turn out to be cause of worry in people with diabetics. Therefore, to keep their blood sugar levels in control, diabetic people are advised to go low on sugary stuff and limit their consumption of food items that are high on carbohydrates. Also, it is recommended that if you are a diabetic, your carbohydrate intake should range somewhere between 45gms-60gms per meal per day. Anything more than that can be a cause of discomfort.</p>
          <p>If sugar and carbohydrates are the main enemies of health of a diabetic person, is honey for diabetic patient a good option, is the question we try to answer through this article.
              Listed below are few recipes that will not only help keep your sugar levels in-check but also alleviate your mood if you find it hard to fight away those sweet cravings.
          </p>
          <div className="tags-social">
            <div className="tags-links">
              <ul>
                <li>
                  <a href="c#" className="no-underline">
                    Honey
                  </a>
                </li>
                <li>
                  <a href="c#" className="no-underline">
                    Cinnamon
                  </a>
                </li>
              </ul>
            </div>
            <div className="socials-links-box">
              <ul>
                <li className="flex items-center">
                  <a href="#s" className="relative">
                    <svg
                      viewBox="0 0 10 16"
                      xmlns="http://www.w3.org/2000/svg"
                      className="top-3 left-2 absolute"
                    >
                      <path d="M8.71875 9L9.15625 6.125H6.375V4.25C6.375 3.4375 6.75 2.6875 8 2.6875H9.28125V0.21875C9.28125 0.21875 8.125 0 7.03125 0C4.75 0 3.25 1.40625 3.25 3.90625V6.125H0.6875V9H3.25V16H6.375V9H8.71875Z" />
                    </svg>
                  </a>
                </li>
                <li className="flex items-center">
                  <a href="#s" className="relative">
                    <svg
                      viewBox="0 0 16 14"
                      xmlns="http://www.w3.org/2000/svg"
                      className="top-3 left-2 absolute"
                    >
                      <path d="M14.3438 3.75C14.9688 3.28125 15.5312 2.71875 15.9688 2.0625C15.4062 2.3125 14.75 2.5 14.0938 2.5625C14.7812 2.15625 15.2812 1.53125 15.5312 0.75C14.9062 1.125 14.1875 1.40625 13.4688 1.5625C12.8438 0.90625 12 0.53125 11.0625 0.53125C9.25 0.53125 7.78125 2 7.78125 3.8125C7.78125 4.0625 7.8125 4.3125 7.875 4.5625C5.15625 4.40625 2.71875 3.09375 1.09375 1.125C0.8125 1.59375 0.65625 2.15625 0.65625 2.78125C0.65625 3.90625 1.21875 4.90625 2.125 5.5C1.59375 5.46875 1.0625 5.34375 0.625 5.09375V5.125C0.625 6.71875 1.75 8.03125 3.25 8.34375C3 8.40625 2.6875 8.46875 2.40625 8.46875C2.1875 8.46875 2 8.4375 1.78125 8.40625C2.1875 9.71875 3.40625 10.6562 4.84375 10.6875C3.71875 11.5625 2.3125 12.0938 0.78125 12.0938C0.5 12.0938 0.25 12.0625 0 12.0312C1.4375 12.9688 3.15625 13.5 5.03125 13.5C11.0625 13.5 14.3438 8.53125 14.3438 4.1875C14.3438 4.03125 14.3438 3.90625 14.3438 3.75Z" />
                    </svg>
                  </a>
                </li>
                <li className="flex items-center">
                  <a href="#s" className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="top-3 left-2 absolute"
                    >
                      <path d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 0 0 2.882 0z"></path>
                    </svg>
                  </a>
                </li>
                <li className="flex items-center">
                  <a href="#s" className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 511 511.9"
                      className="top-3 left-2 absolute"
                    >
                      <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                      <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zM423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                    </svg>
                  </a>
                </li>
                <li className="flex items-center">
                  <a href="#s" className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="top-3 left-2 absolute"
                    >
                      <path d="M453.332 85.332c0 38.293-31.039 69.336-69.332 69.336s-69.332-31.043-69.332-69.336C314.668 47.043 345.707 16 384 16s69.332 31.043 69.332 69.332zm0 0"></path>
                      <path d="M384 170.668c-47.063 0-85.332-38.273-85.332-85.336C298.668 38.273 336.938 0 384 0s85.332 38.273 85.332 85.332c0 47.063-38.27 85.336-85.332 85.336zM384 32c-29.418 0-53.332 23.938-53.332 53.332 0 29.398 23.914 53.336 53.332 53.336s53.332-23.938 53.332-53.336C437.332 55.938 413.418 32 384 32zM453.332 426.668C453.332 464.957 422.293 496 384 496s-69.332-31.043-69.332-69.332c0-38.293 31.039-69.336 69.332-69.336s69.332 31.043 69.332 69.336zm0 0"></path>
                      <path d="M384 512c-47.063 0-85.332-38.273-85.332-85.332 0-47.063 38.27-85.336 85.332-85.336s85.332 38.273 85.332 85.336c0 47.059-38.27 85.332-85.332 85.332zm0-138.668c-29.418 0-53.332 23.938-53.332 53.336C330.668 456.063 354.582 480 384 480s53.332-23.938 53.332-53.332c0-29.398-23.914-53.336-53.332-53.336zM154.668 256c0 38.293-31.043 69.332-69.336 69.332C47.043 325.332 16 294.293 16 256s31.043-69.332 69.332-69.332c38.293 0 69.336 31.039 69.336 69.332zm0 0"></path>
                      <path d="M85.332 341.332C38.273 341.332 0 303.062 0 256s38.273-85.332 85.332-85.332c47.063 0 85.336 38.27 85.336 85.332s-38.273 85.332-85.336 85.332zm0-138.664C55.914 202.668 32 226.602 32 256s23.914 53.332 53.332 53.332c29.422 0 53.336-23.934 53.336-53.332s-23.914-53.332-53.336-53.332zm0 0"></path>
                      <path d="M135.703 245.762c-7.426 0-14.637-3.864-18.562-10.774-5.825-10.218-2.239-23.254 7.98-29.101l197.95-112.852c10.218-5.867 23.253-2.281 29.1 7.977 5.825 10.218 2.24 23.254-7.98 29.101L146.238 242.965a21.195 21.195 0 0 1-10.535 2.797zM333.633 421.762c-3.586 0-7.211-.899-10.54-2.797L125.142 306.113c-10.22-5.824-13.801-18.86-7.977-29.101 5.8-10.239 18.856-13.844 29.098-7.977l197.953 112.852c10.219 5.824 13.8 18.86 7.976 29.101-3.945 6.91-11.156 10.774-18.558 10.774zm0 0"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="prev-next">
            <div className="prev-link">
              <a href="#x" className="flex items-center">
                <span className="icon">
                  <svg viewBox="0 0 50 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM50 7L1 7V9L50 9V7Z"></path>
                  </svg>
                </span>
                <span className="text">previous</span>
              </a>
            </div>
            <div className="next-link">
              <a href="#x" className="flex items-center">
                <span className="text">next</span>
                <span className="icon">
                  <svg viewBox="0 0 50 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M49.7071 7.29289C50.0976 7.68342 50.0976 8.31658 49.7071 8.70711L43.3431 15.0711C42.9526 15.4616 42.3195 15.4616 41.9289 15.0711C41.5384 14.6805 41.5384 14.0474 41.9289 13.6569L47.5858 8L41.9289 2.34315C41.5384 1.95262 41.5384 1.31946 41.9289 0.928932C42.3195 0.538408 42.9526 0.538408 43.3431 0.928932L49.7071 7.29289ZM0 7L49 7V9L0 9L0 7Z"></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Nutribenefit;
