import React, { useEffect, useState } from "react";

import Layout from "../../../components/layout/Layout";
import Img3 from "../../../assets/img/gallery-single/box-img-1.jpg";
import { useSelector, useDispatch } from "react-redux";
import { getAllApitourismAction } from "../../../store/apitourism/actions";
import { getAllApiCategorysAction } from "../../../store/apitourismCategory/actions";
import { Link } from "react-router-dom";
import Tabs from "./Tab";
import ApitourismTab from "../tabcomponent/Apitourism";
import LocationTab from "../tabcomponent/Location";

import Category from "./Categories";
import PaymentMethods from "../tabcomponent/Paymentmode";
const Apitourism = () => {
  const dispatch = useDispatch();

  const [categoryName, setCategoryName] = useState("");
  const { apitourism } = useSelector((state: any) => state);
  // const { ApiCategory  } = useSelector((state: any) => state);
  const limitStringLength = (text: string, maxLength: number): string  => {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength) + "...";
  }
}
  useEffect(() => {
    getAllApiCategorysAction(`?`)(dispatch);
    //console.log(apitourismcategory)
  }, [dispatch]);

  useEffect(() => {
    if (categoryName) {
      getAllApitourismAction(
        `?category=${categoryName}`
      )(dispatch);
    } else {
      getAllApitourismAction(`?`)(dispatch);
      console.log(apitourism)
    }
  }, [dispatch, categoryName]);


  const tabs = [
    {
      label: 'Details',
      content: <ApitourismTab />,
    },
    {
      label: 'Location and visiting time',
      content: <LocationTab />,
    },
    {
      label: 'Payment model',
      content: <PaymentMethods />,
    },
  ];

  return (
    <Layout>
      <section className="breadcum bg-cover-center bg-[url('assets/img/breadcum/bg.jpg')]">
        <h2 className="flex items-center justify-center mx-auto pt-24 lg:pt-0">
          API Tourism
        </h2>
      </section>
      <section className="gallery-display box-spach py-0">
        {/* All information about API Tourism */}

        < Tabs tabs={tabs} />
        {/* End of information */}
        <div className="Container">
          <Category
            setCategoryName={setCategoryName}
            categoryName={categoryName}
          />
          {/* All information about API Tourism */}
          {/* End of information */}
          <div className="cardsx flex flex-wrap gap-4 px-14" >
            {apitourism?.all?.data?.map((el: any) =>
              <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                  <img className="rounded-t-lg" src={el?.picture} alt="" style={{ width: '100%', height: 250 }}/>
                </a>
                <div className="p-5">
                  <a href="#">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{el?.names}</h5>
                  </a>
                  <p className="mb-3 font-normal text-gray-700 ">{limitStringLength(el?.description, 50)}</p>
                  <Link
                    to={`/apitourism/view/${el?._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <a href="#" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-black rounded-lg hover:bg-black focus:ring-4 focus:outline-none focus:ring-black dark:bg-black dark:hover:bg-black dark:focus:ring-black">
                      Read more
                    </a>
                  </Link>
                </div>
              </div>
            )}

          </div>
        </div>


      </section>

    </Layout>
  );
};

export default Apitourism;
