import React from "react";

interface VideoModalProps {
  videoLink: string;
  closeModal: () => void;
}

const VideoPlayModel: React.FC<VideoModalProps> = ({
  videoLink,
  closeModal,
}) => {
  console.log("::>>>", videoLink);
  return (
    <div className="modal" style={{display: "flex"}}>
      <div className="modal-content" id="modal-play">  
        <iframe width="100%" height="100%"  src={videoLink} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        <button onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export default VideoPlayModel;
