import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllService = async (query: string) => {

  return HttpRequest.get(`${SERVER_URL}/apitourism/${query}`);
};
export const getOneApiService = async (query: string) => {
  
  return HttpRequest.get(`${SERVER_URL}/apitourism/one/${query}`);
};

